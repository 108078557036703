import { useDispatch } from "react-redux";
import { removePatient } from "../../../redux/features/patientVisit/patientSlice";
import { deleteAppointment } from "../../../redux/features/allAppointment/appointmentSlice";
import apiServices from "../../../../Service/apiService";


export const useHandleDelete = (detail, toggleState,setState,reset) => {
    const dispatch = useDispatch();
    const token = sessionStorage.getItem("token");
    const handleDelete = async () => {
        toggleState("loadingSpinner",true,setState)
        try {
          const response = await apiServices.delete(
            "authentication",
            `/clinic/deleteAppointment/${detail.id}`,
            {
              Authorization: " Bearer " + token,
            },
            null,
            null
          );
          if (response) {
            dispatch(deleteAppointment(detail.id));
            dispatch(removePatient());
            if(detail.paymentStatus === 'PAID'){
              handleUnpaidPayment(detail,reset, toggleState,setState)
            }else{
              reset();
              toggleState("loadingSpinner",false,setState)
            }
          }
        } catch (error) {
          console.error("error in deleting ", error);
        } finally{
          toggleState("loadingSpinner",false,setState)
        }
      };
      const handleUnpaidPayment = async () => {
        try {
          const response = await apiServices.delete(
            "authentication",
            "/payment/deleteAppointmentPayment",
            null,
           {
            appointmentId : detail.id
           },
            null
          );
          if (response) {
            console.log(response)
            reset();
            toggleState("loadingSpinner",false,setState)
          }
        } catch (error) {
          console.error("error is coming ", error);
        }finally{
          toggleState("loadingSpinner",false,setState)
        }
      };
    return { handleDelete };
  };


 