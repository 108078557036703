import { createSlice } from "@reduxjs/toolkit";
const dentistSlice = createSlice({
  name: "dentistData",
  initialState: {
    loading: false,
    dentistData: null,
    error: null,
    isDentistDataFetched: false, //Add flag to indicate data availablity
  },
  reducers: {
    fetchDataStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess(state, action) {
      state.loading = false;
      state.dentistData = action.payload;
      state.error = null;
      state.isDentistDataFetched = true; // Set flag to true when data is fetched
    },
    fetchDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addClinicDentistSucess(state, action) {
      state.dentistData.push(action.payload); // Assuming payload contains data of the newly added clinic
    },
    updateClinicDentistSuccess(state, action) {
      // find the index of the clinic with the matching id
      const clinicIndex = state.dentistData.findIndex(
        (clinic) => clinic.id === action.payload.id
      );
      // if clinic exists update its details
      if (clinicIndex !== -1) {
        //  Create a new array with the updated clinic
        state.dentistData = [
          ...state.dentistData.slice(0, clinicIndex),
          action.payload, //updated clinic
          ...state.dentistData.slice(clinicIndex + 1), //element after the updated clinic
        ];
      }
    }
  },
});
export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
  addClinicDentistSucess,
  updateClinicDentistSuccess
} = dentistSlice.actions;
export default dentistSlice.reducer;
