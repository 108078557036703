import { useDispatch } from "react-redux";
import apiServices from "../../../../Service/apiService";
import { updateClinicSuccess } from "../../../redux/features/data/dataSlice";
import { handleImageUpload } from "./addClinic_function";
import { ADD_DENTIST_CHARGES_API, EDIT_DENTIST_CHARGES_API, IMAGE_DELETE_API } from "../clinic_api/api";
import { addClinicDentistSucess, updateClinicDentistSuccess } from "../../../redux/features/dentistData/dentistSlice";

export const useEditClinic = (setState, toggleState, reset,image ,id,specialization) => {
    const dispatch = useDispatch();
    const handleEditFunction = async (values) => {
        try {
          toggleState("loadingSpinner", true, setState);
          const response = await apiServices.put(
            "authentication",
            `/clinic/update/${id}`,
            null,
            null,
            {
              clinicName: values.clinicName,
              location: values.location,
              startTime: values.startTime,
              endTime: values.endTime,
              incharge: values.incharge,
              clinicContact: values.clinicContact,
              clinicNewFees: values.clinicNewFees,
              clinicOldFees: values.clinicOldFees,
              days: values.days,
            }
          );
          if (response.statusCode === 200) {
            dispatch(updateClinicSuccess(response.data));
            if (image) {
              handleImageUpload(id,image,toggleState,setState);
            }
            if(specialization){
              handleEditDental(id,values)
            }
            reset();
          }
        } catch (error) {
          console.error("error", error.response);
        } finally {
          toggleState("loadingSpinner", false, setState);
        }
      };
      const handleEditDental= async(id,values)=>{
        try {
          const response = await apiServices.patch(
              "authentication",
              EDIT_DENTIST_CHARGES_API,
              null,
              null,
              {
                  clinicId:id,
                  generalDentistry: values.generalDentistry,
                  cosmeticDentistry: values.cosmeticDentistry,
                  dentalCheckUp: values.dentalCheckUp,
                  restorationAndFilling: values.restorationAndFilling,
                  crownsAndBridges: values.crownsAndBridges,
                  fractureAndTrauma: values.fractureAndTrauma,
                  vaneersAndLaminates: values.vaneersAndLaminates,
                  extraction: values.extraction,
                  oralAndMaxillofacialSurgery: values.oralAndMaxillofacialSurgery,
                  orthodonticsTreatment: values.orthodonticsTreatment,
                  childDentistry: values.childDentistry,
                  implant: values.implant,
                  scalingAndTeethCleaning: values.scalingAndTeethCleaning,
                  rootCanalTreatment: values.rootCanalTreatment
              }
          );
          if (response.statusCode===200) {
            dispatch(updateClinicDentistSuccess(response.data))
          }
      } catch (error) { 
        if (error.response.data.statusCode === 404) {
          handleDentistCharges(id,values)
        }    
        console.error("error", error);
      }
      }
      const handleDentistCharges= async(clinicId,values)=>{
        try {
            const response = await apiServices.post(
                "authentication",
                ADD_DENTIST_CHARGES_API,
                null,
                null,
                {
                    clinicId:clinicId,
                    generalDentistry: values.generalDentistry,
                    cosmeticDentistry: values.cosmeticDentistry,
                    dentalCheckUp: values.dentalCheckUp,
                    restorationAndFilling: values.restorationAndFilling,
                    crownsAndBridges: values.crownsAndBridges,
                    fractureAndTrauma: values.fractureAndTrauma,
                    vaneersAndLaminates: values.vaneersAndLaminates,
                    extraction: values.extraction,
                    oralAndMaxillofacialSurgery: values.oralAndMaxillofacialSurgery,
                    orthodonticsTreatment: values.orthodonticsTreatment,
                    childDentistry: values.childDentistry,
                    implant: values.implant,
                    scalingAndTeethCleaning: values.scalingAndTeethCleaning,
                    rootCanalTreatment: values.rootCanalTreatment
                }
            );
            if (response.statusCode === 201) {
                dispatch(addClinicDentistSucess(response.data))
                console.log("charges added",response.data)
            }
        } catch (error) {
            console.error("error", error);
        }
    }
    return { handleEditFunction }
}



export const onDeletePrescriptionImg = (setState, toggleState, id) => {
    const deletePrescriptionImg = async () => {
        toggleState("loadingSpinner", true, setState);
        try {
          const response = await apiServices.delete(
            "authentication",
            IMAGE_DELETE_API,
            null,
            {
              clinicId: id
            },
            null
          );
          if (response) {
            toggleState("loadingSpinner", false, setState);
          }
        } catch (error) {
          console.error("error in deleting ", error);
        } finally {
          toggleState("loadingSpinner", false, setState);
        }
      }
    return { deletePrescriptionImg }
}