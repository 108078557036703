import React, { useEffect, useState } from 'react'
import useData from '../../../redux/customHook/useData'
import medical from "../../../../assets/MedicalSymbol.png";
import { toggleState } from '../../../specialMethod/toggleState';
import apiServices from '../../../../Service/apiService';

const DentalPrintInvoice = ({ reset, patientDetails, services ,total}) => {
  const [state, setState] = useState({
    doctorDetails: [],
    InvoiceId: 1
  });

  const { data } = useData();
  useEffect(() => {
    if (data) {
      toggleState("doctordDetails", data, setState);
      console.log(data)
      handleGetInvoice(data.id)
    }
  }, [data]);

  const handlePrint = () => {
    window.print();
  }
  const handleGetInvoice = async (doctorId) => {
    try {
      const response = await apiServices.get(
        "authentication",
        `/DoctorInvoiceNo/${doctorId}`,
        null,
        null,
        null
      );
      if (response.statusCode === 200) {
        console.log(response.data.lastInvoiceId)
        toggleState("InvoiceId", response.data.lastInvoiceId, setState);
        handleUpdateInvoice(response.data.lastInvoiceId,doctorId)
      }
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        handleCreateInvoice(doctorId);
      }
    } 
  };
  const handleUpdateInvoice = async (InvoiceId,doctorId) => {
    try {
      const response = await apiServices.patch(
        "authentication",
        "/DoctorInvoiceNo/update",
        null,
        null,
        {
          "doctorId":doctorId,
          "lastInvoiceId":parseInt(InvoiceId)+1
        }
      );
      if (response.statusCode === 200) {
        console.log("updated")
      }
    } catch (error) {
      console.log(error)
    } 
  };
  const handleCreateInvoice = async (doctorId) => {
    try {
      const response = await apiServices.post(
        "authentication",
        "/DoctorInvoiceNo/create",
        null,
        null,
        {
          "doctorId":doctorId,
          "lastInvoiceId":"1"
        }
      );
      if (response.statusCode === 200) {
        console.log("created")
      }
    } catch (error) {
      console.log(error)
    } 
  };

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
    >
      <div className="relative p-4 w-full max-w-3xl h-full md:h-[99%]">
        <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            onClick={reset}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close popup</span>
          </button>

          <div className="">
            <footer className="text-gray-600 body-font">
              <div className="container px-5 pt-6 pb-16 mx-auto">
                <div className="flex flex-wrap -mb-10 -mx-4">
                  <div className="w-4/10 px-2 text-left">
                    <div className="flex items-start w-full mb-0">
                      <img
                        src={medical}
                        alt="Medical"
                        className="w-16 h-16 object-cover rounded-full md:mr-4"
                      />
                      <div className="ml-8">
                        <span className="text-xl text-gray-900 block">
                          {data?.firstName} {data?.lastName}
                        </span>
                        <p className="text-sm text-gray-500">{data?.degree}</p>
                        <p className="text-sm text-gray-500">
                          {data?.specialization}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/3 px-4 pl-18 text-center">
                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                      Location
                    </h2>
                    <nav className="list-none">
                      <li>
                        <span className="text-gray-600 hover:text-gray-800">
                          {patientDetails.clinicLocation}
                        </span>
                      </li>
                    </nav>
                  </div>
                  <div className="w-1/5 px-4 pl-4 text-left">
                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                      Contact
                    </h2>
                    <nav className="list-none">
                      <li>
                        <span className="text-gray-600 hover:text-gray-800">
                          {patientDetails.contact}
                        </span>
                      </li>
                      <li>
                        <span className="text-gray-600 hover:text-gray-800">
                          {data?.email}
                        </span>
                      </li>
                    </nav>
                  </div>

                </div>
              </div>
            </footer>
            <hr className='h-[2px] bg-gray-500 mx-4'></hr>

            <section class="text-gray-600 body-font overflow-hidden">
              <div class="container px-5 mx-auto">
                <div class="divide-y-2 divide-gray-100">
                  <div class="py-8 flex flex-nowrap">

                    <div class="flex-grow">
                      <h2 class="text-md font-base text-gray-900 title-font ">Invoice To</h2>
                      <p class="leading-relaxed"><span className='font-bold text-2xl'>{patientDetails.name} </span>({patientDetails.age} years)</p>
                      <div class="flex flex-wrap mt-4">
                        <div class="w-1/3">
                          <div class="relative">
                            <p class="leading-7 text-sm font-semibold text-gray-600">Mobile no.</p>
                            <p class="w-full  text-sm outline-none text-gray-700">+91 {patientDetails.contact}</p>
                          </div>
                        </div>
                        {patientDetails.abhaNumber !== "" &&
                          <div class="w-1/3">
                            <div class="relative">
                              <p class="leading-7 text-sm font-semibold text-gray-600">Abha No.</p>
                              <p class="w-full  text-sm outline-none text-gray-700">{patientDetails.abhaNumber}</p>
                            </div>
                          </div>}</div>

                    </div>
                    <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col text-right mr-4">
                      <span class=" title-font text-gray-700">Date:- {patientDetails.appointmentDate}</span>
                      <span class="mt-1 font-semibold text-gray-900 text-xl">Invoice :- {state.InvoiceId}</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="text-gray-600 body-font ">
              <div class="container px-2 mx-auto  ">
                <div class="mx-2 border border-gray-600 overflow-auto rounded-lg p-2 ">
                  <table class="table-auto w-full text-left whitespace-no-wrap">
                    <thead>
                      <tr>
                        <th class="px-4 py-3 w-20 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Sr no.</th>
                        <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Treatments</th>
                        <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services?.map((service, index) => (
                        <tr key={index}>
                          <td className="px-4 py-3">{index + 1}</td> {/* Serial number */}
                          <td className="px-4 py-3">{service.name}</td> {/* Service name */}
                          <td className="px-4 py-3 text-right">{service.Charges || "N/A"}</td> {/* Charges */}
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
              </div>
            </section>
            <p className='text-right mt-2 font-semibold text-xl'> Total Sum:- <span className='mx-4'>  ₹ {total}.00</span></p>
          </div>
          <div className="flex justify-end items-center mt-20 mx-4">
            <hr className="h-[2px] w-1/3 bg-gray-500"></hr>
          </div>
          <div className="flex justify-end items-center mx-4">
            <p className="ml-4">SIGNATURE</p>
          </div>
          <button type='button' onClick={handlePrint} className='bg-blue-500 text-white px-4 py-1 rounded-lg mx-4 mb-6'> Print Invoice</button>
        </div>
      </div>
    </div>
  )
}

export default DentalPrintInvoice