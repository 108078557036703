 // Function to convert full day name to single-letter abbreviationzz
const getShortDayName = (fullDayName) => {
  const daysMap = {
    SUNDAY: "S",
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
  };
  return daysMap[fullDayName.toUpperCase()];
};

// Function to generate UI markup for displaying opening days with single-letter abbreviations
export const renderOpeningDays = (clinicDays) => {
    return clinicDays.map((fullDayName) => (
      <span
        key={fullDayName}
        style={{
          marginRight: "0.5em",
        }}
      >
        {getShortDayName(fullDayName)}
      </span>
    ));
  };