import React, { useEffect, useRef, useState } from "react";
import useData from "../../../redux/customHook/useData";
import apiServices from "../../../../Service/apiService";
import html2canvas from "html2canvas";
import EyePrescriptionDownload from "./eyePrescriptionDownload";
import { formatDateToDDMMYY, formatDateToDDMMYYEPRESCRPITON } from "../../../specialMethod/getBetweenDate";
import jsPDF from "jspdf";
import { toggleState } from "../../../specialMethod/toggleState";
import Spinner from "../../loading/spinner";
import dentist from "../../../../assets/dentist.png"

const EpresciptionDownload = ({
  doctorDetails,
  patientData,
  reset,
  prescriptionPhotoURLs,
  prescriptionResult,
  eyeData,
  selectedDate
}) => {
  const { data } = useData();
  const [state, setState] = useState({
    loadingSpinner: false
  });
  const [prescriptionImage, setPrescriptionImage] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [prescriptionUrl, setPrescriptionUrl] = useState(null);
  // Map clinic locations to their IDs
  const clinicLocationToIdMap = data?.clinicDtos?.reduce((map, clinic) => {
    map[clinic.location] = clinic.id;
    return map;
  }, {});
  // Set prescription image based on clinic ID
  useEffect(() => {
    if (data?.clinicDtos && patientData.clinicLocation) {
      // Find the clinic data associated with the patient's location
      const activeClinics = data.clinicDtos.filter(
        (clinic) => clinic.clinicStatus === "Active"
      );
      const clinicData = activeClinics.find(
        (clinic) => clinic.location === patientData.clinicLocation
      );
      if (clinicData) {
        setClinicDetails(clinicData)
        // Set prescription image if the clinic ID is available
        setPrescriptionImage(prescriptionPhotoURLs[clinicData.id]);
      }
    }
  }, [
    clinicLocationToIdMap,
    patientData.clinicLocation,
    prescriptionPhotoURLs,
  ]);
  // const clinicDetails = doctorDetails.clinics;
  const captureRef = useRef(null);
  const excludeRef = useRef();
  const getAllDegree = doctorDetails.degree;
  const handlePrint = () => {
    window.print();
  };
console.log(prescriptionResult)
  useEffect(() => {
    uploadPrisciptionPage()
  }, [])

  const uploadPrisciptionPage = async () => {
    try {
      // Hide the excluded element before capturing
      if (excludeRef.current) excludeRef.current.style.display = "none";
  
      const fetchImageAsBase64 = async (url) => {
        console.log(url)
        const response = await fetch(url);
        console.log("S",response)
        const blob = await response.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      };
  
      // Fetch and embed external images as Base64
      const dentistBase64 = await fetchImageAsBase64(
       "https://rdoc-aid-prod.s3.eu-north-1.amazonaws.com/protocol_dev/dental.jpg"
      );
      console.log("s",dentistBase64)
      const dentistImg = document.querySelector("#dentistImg");
      if (dentistImg) dentistImg.src = dentistBase64;
  console.log("r",dentistImg)
  const medicalPriscriptionBase64 = await fetchImageAsBase64(
    "https://rdoc-aid-prod.s3.eu-north-1.amazonaws.com/protocol_dev/medicalPriscription.jpg"
   );
   console.log("s",medicalPriscriptionBase64)
   const medicalPriscriptionImg = document.querySelector("#medicalPriscriptionImg");
   if (medicalPriscriptionImg) medicalPriscriptionImg.src = medicalPriscriptionBase64;
console.log("r",medicalPriscriptionImg)
      const input = captureRef.current;
      const pdf = new jsPDF("p", "mm", "a4");
  
      // Ensure all images are loaded before capturing
      const images = input.querySelectorAll("img");
      const imageLoadPromises = Array.from(images).map(
        (img) =>
          new Promise((resolve) => {
            if (img.complete) resolve();
            else img.onload = img.onerror = resolve;
          })
      );
      await Promise.all(imageLoadPromises);
  
      // Generate canvas and capture content
      const canvas = await html2canvas(input, {
        scale: 2, // Higher scale for better quality
        useCORS: true,
        allowTaint: false,
      });
  
      // Convert canvas to image data
      const imgData = canvas.toDataURL("image/jpeg", 1);
  
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      // Add the first page
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      // Add additional pages if needed
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      // Restore the excluded element's display property
      if (excludeRef.current) excludeRef.current.style.display = "";
  
      // Convert the PDF to a blob
      const pdfBlob = pdf.output("blob");
      console.log("PDF size in bytes:", pdfBlob.size);
  
      // Prepare FormData for API submission
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("file", pdfBlob, `${patientData.name}_prescription.pdf`);
  
      toggleState("loadingSpinner", true, setState);
  
      // Submit the PDF to the API
      const response = await apiServices.post(
        "authentication",
        "/s3fileUpload/uploadPrescriptionFile",
        null,
        null,
        formDataToSubmit
      );
  
      if (response) {
        setPrescriptionUrl(response);
        handleStorePrescription(response);
      } else {
        console.error("Failed to upload PDF:", response?.status || "Unknown error");
      }
    } catch (error) {
      console.error("Error submitting PDF:", error.message);
    } finally {
      toggleState("loadingSpinner", false, setState);
    }
  };
  
  

  const handleStorePrescription = async (prescription) => {
    try {
      const response = await apiServices.post(
        "authentication",
        "/PrescriptionHistoryData/create",
        null,
        null,
        {
          url: prescription,
          appointmentDate: patientData.appointmentDate,
          drName: doctorDetails.firstName + " " + doctorDetails.lastName,
          doctorSpecialization: doctorDetails.specialization[0],
          patientId: patientData.patientId
        }
      );
      if (response.statusCode === 201) {
        toggleState("loadingSpinner", false, setState);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }finally{
      toggleState("loadingSpinner", false, setState);
    }
  }

  const handleWhatsappPrescription = async (prescription) => {
    try {
      const response = await apiServices.post(
        "authentication",
        "/whatsapp/prescription_confirmation",
        null,
        {
          s3url: prescriptionUrl
        },
        {
          "patientName": patientData.name,
          "patientContact": patientData.contact,
          "drName": doctorDetails.firstName + " " + doctorDetails.lastName,
          "compounderName": clinicDetails?.incharge,
          "clinicContact": clinicDetails?.clinicContact
        }
      );
      if (response) {
        console.log("all data")
        toggleState("loadingSpinner", false, setState);
        reset()
        return response.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      toggleState("loadingSpinner", false, setState);
    }
  }

  const specialization = doctorDetails.specialization[0].toUpperCase();

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
    >
      <div className="relative p-4 w-full max-w-6xl h-full md:h-[99%]">
        <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            onClick={reset}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close popup</span>
          </button>

          <div className="" ref={captureRef}>
            {prescriptionImage ? (
              <img
                className="w-full h-auto"
                src={`data:image/jpeg;base64,${prescriptionImage}`}
                alt="Prescription"
              />
            ) : (
              <div className="p-5">
                <h5 className="text-lg mb-0.5 font-medium">
                  {doctorDetails.firstName} {doctorDetails.lastName}
                </h5>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  {doctorDetails.specialization}
                </p>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  {getAllDegree}
                </p>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  Location: {patientData.clinicLocation}
                </p>
                <p className="mb-4 text-sm font-normal text-gray-800">
                  Mobile No: {patientData.contact}
                </p>
              </div>
            )}

            <form className="w-full">
              <div className="flex items-center justify-around  pl-4 md:p-2 border-t-2 border-gray-200 rounded-b dark:border-black">
                <p className="text-black  text-sm px-1 py-2.5 text-center ">
                  Patient Name: &nbsp;{patientData.name}
                </p>
                <p className="text-black text-sm px-1 py-2.5 text-center ">
                  Age: &nbsp;{patientData.age}
                </p>
                <p className="text-black text-sm px-2 py-2.5 text-center ">
                  Sex: &nbsp;{patientData.gender}
                </p>
                <p className="text-black text-sm px-2 py-2.5 text-center ">
                  Date: &nbsp;{patientData.appointmentDate}
                </p>
              </div>
              <div class="flex flex-wrap ">
                <div
                  className={`${specialization === "EYE SPECIALIST" || specialization === "DENTIST"
                    ? "w-2/3"
                    : ""
                    } `}
                >
                  <section className="text-gray-600 body-font">
                    <div className="container px-5 mx-auto">
                      <div className="flex flex-wrap -m-4">
                        <div className="flex flex-col flex-wrap p-4 w-1/2">
                          <div className="left-3 ">
                            {prescriptionResult.complaint.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900 ">
                                  Complaints
                                </div>
                                {prescriptionResult.complaint.map(
                                  (complaints) => (
                                    <span className="pr-2 pb-2">
                                      {complaints}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                            {prescriptionResult.diagnosis.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Diagnosis
                                </div>
                                {prescriptionResult.diagnosis.map(
                                  (diagnosises) => (
                                    <span className="pr-2 pb-2">
                                      {diagnosises}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                             {prescriptionResult.examination.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Examination
                                </div>
                                {prescriptionResult.examination.map(
                                  (examine) => (
                                    <span className="pr-2 pb-2">
                                      {examine}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                            {prescriptionResult.treatmentInput.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Treatment
                                </div>
                                {prescriptionResult.treatmentInput.map(
                                  (treatnent) => (
                                    <span className="pr-2 pb-2">
                                      {treatnent}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                            <img
                              src="https://rdoc-aid-prod.s3.eu-north-1.amazonaws.com/protocol_dev/medicalPriscription.jpg"
                              alt="Example medical"
                              id="medicalPriscriptionImg"
                              className="w-16 h-13"
                            />
                            {prescriptionResult.medicines.length > 0 && (
                              <div className="mb-4 ">
                                {prescriptionResult.medicines.map((med, i) => (
                                  <div key={i} className="pb-2">
                                    {" "}
                                    <span className="pr-2 pb-2">
                                      {med.name}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.composition}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.dose}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.when}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.frequency}
                                    </span>
                                    <span className="pr-2 pb-2">
                                      {med.duration}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                            {prescriptionResult.procedureInput.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                 Procedure Summary
                                </div>
                                {prescriptionResult.procedureInput.map(
                                  (procedure) => (
                                    <span className="pr-2 pb-2">
                                      {procedure}
                                    </span>
                                  )
                                )}
                              </div>
                            )}
                            {prescriptionResult.test.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Test
                                </div>
                                {prescriptionResult.test.map((tests) => (
                                  <span className="pr-2 pb-2">{tests}</span>
                                ))}
                              </div>
                            )}
                            {prescriptionResult.advice.length > 0 && (
                              <div className="mb-7">
                                <div className="text-xl text-gray-900">
                                  Advice
                                </div>
                                {prescriptionResult.advice.map((advices) => (
                                  <span className="pr-2 pb-2">{advices}</span>
                                ))}
                              </div>
                            )}
                            {formatDateToDDMMYY(selectedDate) !== formatDateToDDMMYY(new Date()) && (
                              <div className="mb-7">
                                <div className="text-lg text-gray-600">
                                  Next Appointment Date :-  <span className="text-gray-900">{formatDateToDDMMYYEPRESCRPITON(selectedDate)}</span>
                                </div>

                              </div>
                            )}
                          </div>
                        </div>
                        <div class="flex flex-col w-1/2 ">
                          <nav class="flex flex-wrap list-none pt-5">
                            {prescriptionResult.height && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Height:- {prescriptionResult.height} cm
                                </p>
                              </li>
                            )}
                            {prescriptionResult.weight && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Weight:- {prescriptionResult.weight} kg
                                </p>
                              </li>
                            )}
                            {prescriptionResult.highbp && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Bp:- {prescriptionResult.highbp}/{" "}
                                  {prescriptionResult.lowbp}
                                </p>
                              </li>
                            )}
                            {prescriptionResult.pulse && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Pulse:- {prescriptionResult.pulse} cm
                                </p>
                              </li>
                            )}
                            {prescriptionResult.spo && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  SPO2:- {prescriptionResult.spo} cm
                                </p>
                              </li>
                            )}
                            {prescriptionResult.temp && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800">
                                  Temp:- {prescriptionResult.temp} cm
                                </p>
                              </li>
                            )}
                          </nav>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                {specialization === "EYE SPECIALIST" && (
                  <EyePrescriptionDownload eyeData={eyeData} />
                )}
                {specialization === "DENTIST" && (
                  <img className="w-1/3 h-1/3 my-auto" src="https://rdoc-aid-prod.s3.eu-north-1.amazonaws.com/protocol_dev/dental.jpg" alt="dentist"  id="dentistImg"/>
                )}
              </div>
              <div
                ref={excludeRef}
                className="flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b dark:border-gray-600"
              >
                <div className="flex">
                  <button
                    data-modal-hide="default-modal"
                    type="button"
                    onClick={handlePrint}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Print
                  </button>
                  <div className="relative mx-2">
                    <button
                      type="button"
                      onClick={handleWhatsappPrescription}
                      className="w-full bg-gray-100 border-green-300 hover:border-green-800 hover:cursor-pointer flex items-center justify-center gap-2 rounded border  focus:border-indigo-500 focus:ring-2 text-[14px] outline-none text-green-500 py-1 px-2 leading-8 transition-colors duration-200 ease-in-out"
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/4423/4423697.png"
                        alt="WhatsApp"
                        className="w-5 h-5"
                      />
                     WhatsApp
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
    </div>
  );
};

export default EpresciptionDownload;
