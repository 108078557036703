import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectData,
  selectLoading,
  selectError,
} from "../features/dentistData/dentistSelector";
import { fetchDentistData } from "../features/dentistData/dentistThunk";
const useDentistData = (data) => {
  const dispatch = useDispatch();
  const dentistData = useSelector(selectData);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  useEffect(() => {
    //  fetch data only if it is not already available in the redux store
    if (!dentistData && data) {
      dispatch(fetchDentistData(data));
    }
  }, [dentistData, dispatch,data]);
  return { dentistData, loading, error };
};
export default useDentistData;