import React, { useState, useRef, useCallback,useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import Spinner from "../loading/spinner";
import ImageCropper from "./imageCropper";
import { dentistFields, formFields, getAllDays } from "./clinic_function/addClinic_function";
import { toggleState } from "../../specialMethod/toggleState";
import { useAddClinic, useOnCropCancel, useOnCropDone } from "./clinic_function/addClinic_function";
import useData from "../../redux/customHook/useData";
import useDentistData from "../../redux/customHook/useDentistData";

const AddClinic = ({ closeAlertClinicBanner, value, updatedClinics }) => {
  const [state, setState] = useState({
    loadingSpinner: false,
    image: "",
    currentPage: false,
  });

  const { data } = useData();
  const {dentistData}=useDentistData(data);
  
 

  const { onCropDone } = useOnCropDone(state.image, toggleState, setState);
  const { onCropCancel } = useOnCropCancel(toggleState, setState);
  const { addClinic } = useAddClinic(setState, toggleState, state.image, value, closeAlertClinicBanner, updatedClinics,data?.specialization[0]?.toLowerCase() === 'dentist');

  const inputRef = useRef();

  // Memoized handler for image selection
  const handleImageChange = useCallback((event) => {
    if (event.target.files?.length) {
      const reader = new FileReader();
      reader.onload = () => toggleState("image", reader.result, setState); toggleState("currentPage",true,setState)
      reader.readAsDataURL(event.target.files[0]);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      clinicName: "",
      location: "",
      startTime: "",
      endTime: "",
      incharge: "",
      clinicContact: "",
      clinicNewFees: "",
      clinicOldFees: "",
      days: [],
      dropdownOpen: false,
      generalDentistry: dentistData[0]?.generalDentistry || 0,
      cosmeticDentistry:  dentistData[0]?.cosmeticDentistry || 0,
      dentalCheckUp:  dentistData[0]?.dentalCheckUp || 0,
      restorationAndFilling:  dentistData[0]?.restorationAndFilling || 0,
      crownsAndBridges:  dentistData[0]?.crownsAndBridges || 0,
      fractureAndTrauma:  dentistData[0]?.fractureAndTrauma || 0,
      vaneersAndLaminates:  dentistData[0]?.vaneersAndLaminates || 0,
      extraction:  dentistData[0]?.extraction || 0,
      oralAndMaxillofacialSurgery:  dentistData[0]?.oralAndMaxillofacialSurgery || 0,
      orthodonticsTreatment:  dentistData[0]?.oralAndMaxillofacialSurgery || 0,
      childDentistry: dentistData[0]?.childDentistry || 0,
      implant:  dentistData[0]?.implant || 0,
      scalingAndTeethCleaning:  dentistData[0]?.scalingAndTeethCleaning || 0,
      rootCanalTreatment:  dentistData[0]?.rootCanalTreatment || 0
    },
    validationSchema: Yup.object({
      clinicName: Yup.string().required("Enter clinic name"),
      location: Yup.string().required("Enter location"),
      startTime: Yup.string().required("Enter start time"),
      endTime: Yup.string().required("Enter end time"),
      incharge: Yup.string().required("Enter incharge name"),
      clinicContact: Yup.string().matches(/^\d{10}$/, "Must be 10 digits").required("Enter mobile number"),
      days: Yup.array().min(1, "Select at least one day"),
    }),
    onSubmit: (values) => {
      addClinic(values);
    },
  });

  // Toggle dropdown
  const handleToggleDropDown = useCallback(() => {
    formik.setFieldValue("dropdownOpen", !formik.values.dropdownOpen);
  }, [formik]);

  // Handle day selection
  const handleDaySelection = useCallback((day) => {
    const updatedDays = formik.values.days.includes(day)
      ? formik.values.days.filter((d) => d !== day)
      : [...formik.values.days, day];
    formik.setFieldValue("days", updatedDays);
  }, [formik]);

  return (
    <>
      <div
        id="login-popup"
        className="bg-black/50 fixed inset-0 flex items-center justify-center z-50"
      >
        <div className="h-[80%] md:h-[92%] overflow-y-auto">
          <div className="w-full max-w-6xl">
            <div className="bg-white rounded-lg shadow p-5 relative">
              {/* Close Button */}
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-900"
                onClick={value}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              {/* Title */}
              <div className="text-center ">
                <h2 className="text-2xl font-semibold">Clinic Details</h2>
              </div>
              <hr className="my-1"></hr>
              {/* Form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                {data?.specialization[0]?.toLowerCase() === 'dentist' ?
                  <>
                    <div className="grid grid-cols-3 gap-6">
                      {/* Image Upload */}
                      <div className="col-span-3 sm:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Prescription Photo
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          ref={inputRef}
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                        <button
                          type="button"
                          className="text-blue-600"
                          onClick={() => inputRef.current.click()}
                        >
                          {state.image ? "Image Selected" : "Choose Image"}
                        </button>
                      </div>

                      {/* Dynamically Rendered Fields */}
                      {formFields.map(({ name, label, type, placeholder }) => {
                        if (name === 'clinicNewFees' || name === 'clinicOldFees') {
                          return null
                        }
                        return (
                          <div>
                            <div key={name} className="col-span-3 sm:col-span-1">
                              <label className="block text-sm font-medium text-gray-700">{label}</label>
                              <input
                                type={type}
                                name={name}
                                placeholder={placeholder}
                                value={formik.values[name]}
                                onChange={formik.handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:border-blue-500"
                              />
                              {formik.touched[name] && formik.errors[name] && (
                                <p className="text-red-500 text-sm">{formik.errors[name]}</p>
                              )}
                            </div>
                          </div>
                        )
                      })}
                      

                      {/* Days Selection */}
                      <div className="col-span-3 sm:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Select Days</label>
                        <button
                          type="button"
                          className="text-gray-700 bg-white border border-gray-400 rounded-lg px-6 py-2"
                          onClick={handleToggleDropDown}
                        >
                          {formik.values.days.length} Days
                        </button>
                        {formik.values.dropdownOpen && (
                          <ul className="mt-2 p-3 border rounded bg-white">
                            {getAllDays.map((day, idx) => (
                              <li key={idx}>
                                <label className="flex items-center space-x-2">
                                  <input
                                    type="checkbox"
                                    checked={formik.values.days.includes(day)}
                                    onChange={() => handleDaySelection(day)}
                                  />
                                  <span>{day}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        )}
                     
                    </div> </div>
                    <div className="font-semibold my-1"> Service & Charges</div>
                    <div className="grid grid-cols-3 gap-6  p-2 rounded-lg my-2">
                      {dentistFields.map(({ name, label, type, placeholder }) => (
                        <>
                        <div class="flex border border-gray-200 py-2">
                          <span class="text-gray-500 ml-1">{label}</span>
                          <span class="ml-auto text-gray-900 border-l border-gray-800"> <input
                            type={type}
                            name={name}
                            placeholder="00"
                            value={formik.values[name]}
                            onChange={formik.handleChange}
                            className="w-10 ml-2"
                          /></span>
                        </div>
                         {formik.touched[name] && formik.errors[name] && (
                          <p className="text-red-500 text-sm">{formik.errors[name]}</p>
                        )}
                        </>
                      ))}
                    </div>
                  </> :
                  <div className="grid grid-cols-2 gap-6">
                    {/* Image Upload */}
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Prescription Photo
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        ref={inputRef}
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                      <button
                        type="button"
                        className="text-blue-600"
                        onClick={() => inputRef.current.click()}
                      >
                        {state.image ? "Image Selected" : "Choose Image"}
                      </button>
                    </div>

                    {/* Dynamically Rendered Fields */}
                    {formFields.map(({ name, label, type, placeholder }) => (
                      <div key={name} className="col-span-2 sm:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">{label}</label>
                        <input
                          type={type}
                          name={name}
                          placeholder={placeholder}
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          className="w-full px-4 py-2 border rounded-lg focus:border-blue-500"
                        />
                        {formik.touched[name] && formik.errors[name] && (
                          <p className="text-red-500 text-sm">{formik.errors[name]}</p>
                        )}
                      </div>
                    ))}

                    {/* Days Selection */}
                    <div className="col-span-2 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">Select Days</label>
                      <button
                        type="button"
                        className="text-gray-700 bg-white border border-gray-400 rounded-lg px-6 py-2"
                        onClick={handleToggleDropDown}
                      >
                        {formik.values.days.length} Days
                      </button>
                      {formik.values.dropdownOpen && (
                        <ul className="mt-2 p-3 border rounded bg-white">
                          {getAllDays.map((day, idx) => (
                            <li key={idx}>
                              <label className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  checked={formik.values.days.includes(day)}
                                  onChange={() => handleDaySelection(day)}
                                />
                                <span>{day}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                }
                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full bg-orange-500 hover:bg-orange-600 text-white rounded-lg py-2 mt-4"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {state.currentPage && (
        <ImageCropper
          image={state.image}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      )}
      {state.loadingSpinner && <Spinner />}
      <ToastContainer />
    </>
  );
};

export default AddClinic;
