import React, { useState } from 'react'
import { renderOpeningDays } from "../clinic_function/main_clinic_function";
import DetailService from './detail_service';

const DentalClinicCard = ({ clinic, onEdit, onDelete, onCheckAppointments, dentistData }) => {
  const [detail, setDetail] = useState(false)
  console.log(clinic)
  return (
    <div className="max-w-md bg-white border-gray-100 border-2 rounded-xl overflow-hidden shadow-lg mt-4">
      <div className="px-4 pb-8">
        <div className="py-2 my-1">
          <section className="text-gray-600 body-font">
            <div className=" mx-auto flex items-center flex-row ">
              <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto text-left ">
                <h3
                  className="font-bold text-xl mb-1  montserrat"
                  style={{ color: "rgb(251,146,31)" }}
                >
                  {clinic.clinicName}
                </h3>
              </div>
              <div className="flex md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
                <span
                  className="text-black cursor-pointer justify-end"
                  onClick={() => onEdit(clinic)}
                >

                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 19.0003H17M2.666 11.1873C2.23962 11.6147 2.00011 12.1937 2 12.7973V16.0003H5.223C5.827 16.0003 6.406 15.7603 6.833 15.3323L16.333 5.82734C16.7592 5.39993 16.9986 4.82095 16.9986 4.21734C16.9986 3.61372 16.7592 3.03474 16.333 2.60734L15.395 1.66734C15.1835 1.45569 14.9323 1.28781 14.6558 1.1733C14.3794 1.0588 14.083 0.999907 13.7838 1C13.4846 1.00009 13.1883 1.05917 12.9119 1.17385C12.6355 1.28852 12.3844 1.45656 12.173 1.66834L2.666 11.1873Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                </span>
                <span
                  className="text-black cursor-pointer justify-end"
                  onClick={() => onDelete(clinic.id)}
                >
                  <svg
                    className="w-6 h-6 text-red-500 fill-current"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 2a1 1 0 011 1v1h6a1 1 0 110 2h-1v11a2 2 0 01-2 2H7a2 2 0 01-2-2V6H4a1 1 0 110-2h6V3a1 1 0 011-1zM7 6v11h6V6H7zm2-3v1h2V3H9z" />
                  </svg>
                </span>
              </div>
            </div>
          </section>

          <div className="flex items-center">
            <svg
              className="h-5 w-5 text-gray-600"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
            </svg>
            <h3 className="text-sm ml-1">{clinic.location}</h3>
          </div>
          <div className="flex items-center ml-1 mt-1">
            <svg width="13" height="13" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.92222 7.50125L8.36686 7.32367C8.18398 7.30219 7.9986 7.32244 7.82467 7.38289C7.65073 7.44335 7.49276 7.54243 7.36262 7.67271L6.23591 8.79943C4.49771 7.91516 3.08483 6.50227 2.20056 4.76406L3.3334 3.63122C3.59671 3.36791 3.7253 3.0005 3.68243 2.62697L3.50485 1.08386C3.47027 0.785085 3.32695 0.509488 3.10222 0.3096C2.87748 0.109712 2.58706 -0.000489921 2.28629 1.63739e-06H1.22694C0.534989 1.63739e-06 -0.0406146 0.575608 0.00224943 1.26756C0.326792 6.497 4.5091 10.6732 9.73239 10.9978C10.4243 11.0406 10.9999 10.465 10.9999 9.77306V8.7137C11.0061 8.09523 10.5407 7.57473 9.92222 7.50125Z" fill="black" fillOpacity="0.75" />
            </svg>
            <h3 className="text-sm ml-1"> +91 {clinic.clinicContact}</h3>
          </div>
          <hr className='my-1'></hr>
          <div style={{ color: "rgb(251,146,31)" }}> Days</div>
          <div className='font-semibold'>{clinic.days ? renderOpeningDays(clinic.days) : "No opening days"}</div>
          <div className="flex justify-between">
            <div>
              <div style={{ color: "rgb(251,146,31)" }}>Compounder Name</div>
              <div className='font-semibold'>{clinic.incharge}</div>
            </div>
            <div>
              <div style={{ color: "rgb(251,146,31)" }}>Timings</div>
              <div className='font-semibold'>{clinic.startTime.substring(0, 5)} -{" "}
                {clinic.endTime.substring(0, 5)}</div>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <button
            className="rounded-lg text-white bg-teal-600 px-10 xl:px-12 py-2 font-semibold"
            onClick={() => onCheckAppointments(clinic.id)}
          >
            Check Appointments
          </button>
          <button
            className="flex-1 rounded-lg text-teal-600 border border-teal-600 px-4 py-2 font-bold"
            onClick={() => setDetail(true, clinic.id)}
          >
            Details
          </button>
        </div>
      </div>
      {detail && <DetailService clinicId={clinic.id} reset={() => setDetail(false)} onCheckAppointments={onCheckAppointments} dentistData={dentistData} />}
    </div>
  )
}

export default DentalClinicCard