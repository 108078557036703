import React from "react";

const DetailService = ({ clinicId, reset, onCheckAppointments, dentistData }) => {
  // Filter clinic data based on clinicId
  const mainData = dentistData?.filter((clinic) => clinic.clinicId === clinicId) || [];
  
  // Transform the data only if mainData[0] exists
  const transformedData = mainData[0]
    ? Object.entries(mainData[0])
        .filter(([key]) => key !== "clinicId") // Exclude `clinicId`
        .map(([key, value]) => ({
          name: key
            .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
            .trim() // Remove extra spaces
            .replace("Surgery", "Surg.") // Replace "Surgery"
            .replace(/\b\w/g, (char) => char.toUpperCase()), // Capitalize first letter of each word
          serviceCharges: value,
        }))
    : [];
console.log("s",transformedData)
  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden"
    >
      <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto"
            onClick={reset}
            aria-label="Close popup"
          >
            <svg
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div className="p-5 text-center">
            <p className="mb-3 text-2xl font-semibold text-slate-900">
              Services & Charges
            </p>
            
           {transformedData.length>0  ? <div className="grid grid-cols-2 gap-1 border border-gray-300 p-2 rounded-lg my-2">
              {transformedData.map((service, index) => (
                <div key={index} className="flex border-gray-200 py-2">
                  <span className="text-gray-500">{service.name}</span>
                  <span className="ml-auto text-gray-900">
                    {service.serviceCharges}
                  </span>
                </div>
              ))}
            </div> :
              <p className="mb-3 text-lg text-left  text-slate-900">
            No Services Selected
            </p>
}
            <button
              type="button"
              className="bg-teal-600 w-full text-white py-2 border border-gray-300 rounded-lg"
              onClick={() => onCheckAppointments(clinicId)}
            >
              Check Appointments
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailService;
