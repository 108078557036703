import React, { useEffect, useRef, useState } from "react";
import NavbarWrapper from "../../../../widgets/navbarWrapper";
import { useParams, useLocation } from "react-router-dom";
import UploadPrescription from "./uploadPrescription";
import Spinner from "../../loading/spinner";
import apiServices from "../../../../Service/apiService";
import { toggleState } from "../../../specialMethod/toggleState";

const PriscriptionDocument = () => {
  const { id } = useParams();
  const location = useLocation();
  const { patient } = location.state || {};
  const scrollContainerRef = useRef(null);

  const [state, setState] = useState({
    loadingSpinner: false,
    selectedPrescription: null,
    showUpload: false,
    prescriptionData: [],
  });

  const SpecializationList = [
    "Cardiologist",
    "Dentist",
    "Dermatologist",
    "ENT Specialist",
    "Endocrinologist",
    "Eye Doctor",
    "Gastroenterologist",
    "General Physician ",
    "General Surgeon",
    "Gynecologist and Obstetrician",
    "Nephrologist",
    "Neurologist",
    "Oncologist",
    "Orthopedic Surgeon",
    "Pediatrician",
    "Physiotherapist",
    "Psychiatrist",
    "Radiologist",
    "Urologist"

  ];

  useEffect(() => {
    GetPrescription();
  }, []);

  const GetPrescription = async () => {
    try {
      const response = await apiServices.get(
        "authentication",
        "/PrescriptionHistoryData/getByPatientId",
        null,
        { patientId: id },
        null
      );
      if (response) {
        toggleState("prescriptionData", response.data, setState);
      }
    } catch (error) {
      console.error("Error fetching prescriptions:", error);
    }
  };

  const filterBySpecialization = (specialization) => {
    return state.prescriptionData.filter(
      (item) =>
        item.doctorSpecialization &&
        item.doctorSpecialization.toLowerCase() === specialization.toLowerCase()
    );
  };

  const sortedSpecializations = SpecializationList.sort((a, b) => {
    const countA = filterBySpecialization(a).length;
    const countB = filterBySpecialization(b).length;
    return countB - countA; // Sort descending by count
  });

  const handleDoubleClickScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const isAtStart = container.scrollLeft === 0;
      container.scrollLeft = isAtStart ? container.scrollWidth : 0;
    }
  };

  return (
    <>
      <NavbarWrapper showNavbar={true} />

      {/* Header Section */}
      <div className="flex flex-wrap items-center pt-0 md:pt-24 justify-between max-w-screen-xl px-4 py-2.5 mx-auto">
        <h1 className="text-gray-900 font-bold text-2xl">Previous Prescriptions</h1>
        <button
          onClick={() => toggleState("showUpload", true, setState)}
          className="text-white font-medium rounded-lg text-sm px-4 py-2"
          style={{ backgroundColor: "rgb(251,146,31)" }}
        >
          Add Prescription
        </button>
      </div>

      {/* Scrollable Prescriptions Section */}
      <section className="text-gray-600 body-font mx-5">
        <div className="container px-5 mx-auto">
          {/* Headings */}
          <div className="flex ">
            <div className="w-1/4 px-4 py-3 sticky left-0 bg-white ">
              <h2 className="font-bold text-xl text-gray-900">Specialization</h2>
            </div>
            <div className="w-3/4 px-4 py-3">
              <h2 className="font-bold text-xl text-gray-900">
                Prescription by Doctors
              </h2>
            </div>
          </div>

          {/* Scrollable Container */}
          <div
            className="overflow-x-auto "
            ref={scrollContainerRef}
            onDoubleClick={handleDoubleClickScroll}
          >
            {sortedSpecializations.map((specialization) => {
              const filteredPrescriptions = filterBySpecialization(specialization);
              return (
                <div key={specialization} className="flex ">
                  {/* Fixed Specialization Column */}
                  <div className="w-1/4 px-4 py-3 sticky left-0 bg-white">
                    <div className="border-2 border-gray-200 p-5">
                      <h2 className="title-font font-medium text-lg text-gray-900">
                        {specialization}
                      </h2>
                    </div>
                  </div>

                  {/* Scrollable Prescription Row */}
                  <div className="w-3/4 px-4 py-3">
                    {filteredPrescriptions.length > 0 ? (
                      <div className="flex space-x-4">
                        {filteredPrescriptions.reverse().map((prescription) => (
                          <div
                            key={prescription.id}
                            className="p-2 w-48 border-2 border-gray-200 flex-shrink-0"
                          >
                            {/* Doctor's Name */}
                            <a
                              href={prescription.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline font-medium text-lg"
                            >
                              {prescription.drName}
                            </a>
                            <p className="text-gray-600 text-sm mt-2">
                              {prescription.appointmentDate}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-600">No prescriptions available</p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Upload Prescription Modal */}
      {state.showUpload && (
        <UploadPrescription
          reset={() => toggleState("showUpload", false, setState)}
          getNewPrescription={(newPrescription) =>
            setState((prev) => ({
              ...prev,
              prescriptionData: [...prev.prescriptionData, newPrescription],
            }))
          }
          patient={patient}
        />
      )}

      {/* Loading Spinner */}
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default PriscriptionDocument;

