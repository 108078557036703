import ApiServices from "../../../Service/apiService";

export async function fetchDentistApi(data) {
  const clinicIds = data.clinicDtos.filter((clinic)=> clinic.clinicStatus !== 'InActive').map((clinic)=>clinic.id)
  const queryString = clinicIds.join(","); 
  try {
    const response = await ApiServices.get(
      "authentication",
      "/dentalCharges/getByListOfClinicIds",
      null,
      {
        clinicIds :queryString
      },null
    );
    if (response.statusCode === 200) {
      return response.data;
    }
  } catch (error) {
    if (error.response.data.statusCode === 404) {
      console.log("eror", error);
    }
  }
}
