import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../loading/spinner";
import ImageCropper from "./imageCropper";
import { dentistFields, formFields, getAllDays, useOnCropCancel, useOnCropDone } from "./clinic_function/addClinic_function";
import { toggleState } from "../../specialMethod/toggleState";
import { onDeletePrescriptionImg, useEditClinic } from "./clinic_function/editClinic_function";
import useData from "../../redux/customHook/useData";
import useDentistData from "../../redux/customHook/useDentistData";

const EditClinic = ({ clinic, reset }) => {
  const [state, setState] = useState({
    loadingSpinner: false,
    image: "",
    currentPage: false,
  });

  const inputRef = useRef();
  const { data } = useData()
  const {dentistData} =useDentistData();
  const mainData= dentistData.filter((clinics)=> clinics.clinicId === clinic.id)
  console.log(mainData)
  // Form validation schema
  const validationSchema = Yup.object().shape({
    clinicName: Yup.string().required("Enter clinic name"),
    location: Yup.string().required("Enter location"),
    startTime: Yup.string().required("Enter start time"),
    endTime: Yup.string().required("Enter end time"),
    incharge: Yup.string().required("Enter incharge name"),
    clinicContact: Yup.string()
      .matches(/^\d{10}$/, "Mobile number must be 10 digits")
      .required("Enter mobile number"),
    clinicNewFees: Yup.number().required("Add clinic fees"),
    clinicOldFees: Yup.number().required("Add clinic fees"),
    days: Yup.array().required("Please add days"),
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      clinicName: clinic?.clinicName || "",
      location: clinic?.location || "",
      startTime: clinic?.startTime || "",
      endTime: clinic?.endTime || "",
      incharge: clinic?.incharge || "",
      clinicContact: clinic?.clinicContact || "",
      clinicNewFees: clinic?.clinicNewFees || 0,
      clinicOldFees: clinic?.clinicOldFees || 0,
      days: clinic?.days || [],
      dropdownOpen: false,
      generalDentistry: mainData[0]?.generalDentistry || 0,
      cosmeticDentistry: mainData[0]?.cosmeticDentistry || 0,
      dentalCheckUp: mainData[0]?.dentalCheckUp || 0,
      restorationAndFilling: mainData[0]?.restorationAndFilling || 0,
      crownsAndBridges: mainData[0]?.crownsAndBridges || 0,
      fractureAndTrauma: mainData[0]?.fractureAndTrauma || 0,
      vaneersAndLaminates: mainData[0]?.vaneersAndLaminates || 0,
      extraction: mainData[0]?.extraction || 0,
      oralAndMaxillofacialSurgery: mainData[0]?.oralAndMaxillofacialSurgery || 0,
      orthodonticsTreatment: mainData[0]?.orthodonticsTreatment || 0,
      childDentistry:mainData[0]?.childDentistry || 0,
      implant: mainData[0]?.implant || 0,
      scalingAndTeethCleaning: mainData[0]?.scalingAndTeethCleaning || 0,
      rootCanalTreatment: mainData[0]?.rootCanalTreatment || 0
    },
    validationSchema,
    onSubmit: (values) => handleEditFunction(values),
  });

  // Helper function to toggle selected days
  const toggleDaySelection = (day) => {
    const normalizedDay = day.toUpperCase();
    const selectedDays = formik.values.days;
    const updatedDays = selectedDays.includes(normalizedDay)
      ? selectedDays.filter((d) => d !== normalizedDay)
      : [...selectedDays, normalizedDay];
    formik.setFieldValue("days", updatedDays);
  };

  // Event handlers from custom hooks
  const { onCropDone } = useOnCropDone(state.image, toggleState, setState);
  const { onCropCancel } = useOnCropCancel(toggleState, setState);
  const { handleEditFunction } = useEditClinic(setState, toggleState, reset, state.image, clinic?.id,data?.specialization[0]?.toLowerCase() === 'dentist');
  const { deletePrescriptionImg } = onDeletePrescriptionImg(toggleState, setState, clinic?.id);

  // Handle image selection
  const handleFileChange = (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => onImageSelected(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const onImageSelected = (image) => {
    toggleState("image", image, setState);
    toggleState("currentPage", true, setState);
  };

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full flex items-center justify-center"
      >

        <div className={`relative w-full  p-4 h-full ${data?.specialization[0]?.toLowerCase() === 'dentist' ? "max-w-5xl" : "max-w-md"} md:h-auto`}>
          <div className="h-[80%] md:h-[92%] overflow-y-auto"> <div className="bg-white rounded-lg shadow relative">
            {/* Close Button */}
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close popup</span>
            </button>

            {/* Form Content */}
            <div className="p-5">
              <p className="text-center mb-4 text-2xl font-semibold leading-5 text-slate-900">
                Clinic Details
              </p>
              <hr className="h-[1px]  bg-gray-600 my-1 mb-3"></hr>
              <form onSubmit={formik.handleSubmit}>
                {data?.specialization[0]?.toLowerCase() === 'dentist' ?
                  <>
                    <div className="grid grid-cols-3 gap-6">
                      {/* Image Upload */}
                      <div className="col-span-3 sm:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Prescription Photo
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          ref={inputRef}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <button
                          type="button"
                          className="text-blue-600"
                          onClick={() => inputRef.current.click()}
                        >
                          {state.image ? "Image Selected" : "Choose Image"}
                        </button>
                      </div>

                      {/* Dynamically Rendered Fields */}
                      {formFields.map(({ name, label, type, placeholder }) => {
                        if (name === 'clinicNewFees' || name === 'clinicOldFees') {
                          return null
                        }
                        return (
                          <div>
                            <div key={name} className="col-span-3 sm:col-span-1">
                              <label className="block text-sm font-medium text-gray-700">{label}</label>
                              <input
                                type={type}
                                name={name}
                                placeholder={placeholder}
                                value={formik.values[name]}
                                onChange={formik.handleChange}
                                className="w-full px-4 py-2 border rounded-lg focus:border-blue-500"
                              />
                              {formik.touched[name] && formik.errors[name] && (
                                <p className="text-red-500 text-sm">{formik.errors[name]}</p>
                              )}
                            </div>
                          </div>
                        )
                      })}


                      {/* Days Selection */}
                      <div className="col-span-3 sm:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">Select Days</label>
                        <button
                          type="button"
                          className="text-gray-700 bg-white border border-gray-400 rounded-lg px-6 py-2"
                          onClick={() => formik.setFieldValue("dropdownOpen", !formik.values.dropdownOpen)}
                        >
                          {formik.values.days.length} Days
                        </button>
                        {formik.values.dropdownOpen && (
                          <ul className="mt-2 p-3 border rounded bg-white">
                            {getAllDays.map((day, idx) => (
                              <li key={idx}>
                                <label className="flex items-center space-x-2">
                                  <input
                                    type="checkbox"
                                    checked={formik.values.days.includes(day)}
                                    onChange={() => toggleDaySelection(day)}
                                  />
                                  <span>{day}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        )}

                      </div> </div>

                    <div className="font-semibold my-1"> Service & Charges</div>
                    <div className="grid grid-cols-3 gap-6  p-2 rounded-lg my-2">
                      {dentistFields.map(({ name, label, type, placeholder }) => (
                        <>
                          <div class="flex border border-gray-200 py-2">
                            <span class="text-gray-500 ml-1">{label}</span>
                            <span class="ml-auto text-gray-900 border-l border-gray-800"> <input
                              type={type}
                              name={name}
                              placeholder="00"
                              value={formik.values[name]}
                              onChange={formik.handleChange}
                              className="w-10 ml-2"
                            /></span>
                          </div>
                          {formik.touched[name] && formik.errors[name] && (
                            <p className="text-red-500 text-sm">{formik.errors[name]}</p>
                          )}
                        </>
                      ))}
                    </div>
                  </> :
                  <div className="grid grid-cols-2 gap-6">
                    {/* File Input */}
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Prescription Photo
                      </label>
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          ref={inputRef}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <p className="text-blue-600 flex items-center">
                          <span onClick={() => inputRef.current.click()}>
                            {state.image ? "Image Already Chosen" : "Choose Image"}
                          </span>
                          {state.image && (
                            <span
                              className="pl-4 text-red-500 cursor-pointer"
                              onClick={deletePrescriptionImg}
                            >
                              <svg
                                className="w-6 h-6 text-red-500 fill-current"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M9 2a1 1 0 011 1v1h6a1 1 0 110 2h-1v11a2 2 0 01-2 2H7a2 2 0 01-2-2V6H4a1 1 0 110-2h6V3a1 1 0 011-1zM7 6v11h6V6H7zm2-3v1h2V3H9z" />
                              </svg>
                            </span>
                          )}
                        </p>
                      </div>
                    </div>

                    {/* Dynamic Input Fields */}
                    {formFields.map(({ label, name, type, placeholder }) => (
                      <div className="col-span-2 sm:col-span-1" key={name}>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          {label}
                        </label>
                        <input
                          type={type}
                          name={name}
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          placeholder={placeholder}
                          className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                        {formik.touched[name] && formik.errors[name] && (
                          <p className="text-red-500">{formik.errors[name]}</p>
                        )}
                      </div>
                    ))}

                    {/* Days Selector */}
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Select Days
                      </label>
                      <button
                        type="button"
                        className="text-gray-700 bg-white border border-gray-400 px-4 py-2 rounded-lg focus:ring focus:outline-none"
                        onClick={() => formik.setFieldValue("dropdownOpen", !formik.values.dropdownOpen)}
                      >
                        {formik.values.days.length} Days Selected
                      </button>
                      {formik.values.dropdownOpen && (
                        <ul className="absolute mt-2 bg-white shadow-lg rounded-lg p-3 w-48">
                          {getAllDays.map((day) => (
                            <li key={day} className="p-2 flex items-center">
                              <input
                                type="checkbox"
                                checked={formik.values.days.includes(day.toUpperCase())}
                                onChange={() => toggleDaySelection(day)}
                                className="mr-2"
                              />
                              {day}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                }

                {/* Submit Button */}
                <button
                  type="submit"
                  className="mt-8 w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          </div>
        </div></div>

      {/* Image Cropper */}
      {state.currentPage && (
        <ImageCropper image={state.image} onCropDone={onCropDone} onCropCancel={onCropCancel} />
      )}

      {/* Spinner */}
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default EditClinic;
