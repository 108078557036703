import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../loading/spinner";
import apiServices from "../../../Service/apiService";
import { useDispatch } from "react-redux";
import { updatePatientNonVisited, updateUnPaidPaymentStatus } from "../../redux/features/allAppointment/appointmentSlice";
import { subtractClinicAmount} from "../../redux/features/clinicIncome/clinicIncomeSlice";
import useData from "../../redux/customHook/useData";

const SettleDuePayment = ({ patientDetails, reset }) => {
  const {data}= useData();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loadingSpinner: false,
    getPayment: "",
    clinicId: null
  });
  useEffect(() => {
    if (data && data.clinicDtos) {
      const clinicData = data.clinicDtos.find(
        (clinic) => clinic.location === patientDetails.clinicLocation
      );
      toggleState("clinicId", clinicData.id);
    }
  }, []);

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const handlegetPayment = async () => {
      toggleState("loadingSpinner", true);
      try {
        const response = await apiServices.get(
          "authentication",
          `/payment/getAppointmentPayment/${patientDetails.id}`,
         null
        );
        if (response) {
          console.log(response.data);
          toggleState("getPayment", response.data);
          toggleState("loadingSpinner", false);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Error:", error);
        } else {
          console.error("Error:", error);
        }
      } finally {
        toggleState("loadingSpinner", false);
      }
    };
    handlegetPayment();
  }, [patientDetails.id]);
  const formik = useFormik({
    initialValues: {
      fees: "",
      modePayment: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      fees: Yup.string().required("fees  is required"),
      modePayment: Yup.string().required("Mode Of Payment is required"),
    }),
    onSubmit: (values) => {
      handleEditPayment(values);
    },
  });
  useEffect(() => {
    if (state.getPayment) {
      formik.setValues({
        fees: state.getPayment.amount ?? "",
        modePayment: state.getPayment.modeOfPayment || "",
      });
    }
  }, [state.getPayment]);

  const handleEditPayment = async (values) => {
    toggleState("loadingSpinner", true);
    try {
      const response = await apiServices.patch(
        "authentication",
        "/payment/updateAppointmentPayment",
        null,
        null,
        {
          modeOfPayment: values.modePayment,
          amount: values.fees,
          appointmentDate: patientDetails.appointmentDate,
          appointmentId : patientDetails.id,
          clinicId: state.clinicId,
          doctorId: data.id
        }
      );
      if (response) {
        console.log(response.data);
        toggleState("loadingSpinner", false);
        reset();
      }
    } catch (error) {
      console.error("error is coming ", error);
    } finally {
      toggleState("loadingSpinner", false);
    }
  };

  const handleUnpaidPayment = async () => {
    toggleState("loadingSpinner", true);
    try {
      const response = await apiServices.delete(
        "authentication",
        "/payment/deleteAppointmentPayment",
        null,
       {
        appointmentId : patientDetails.id
       },
        null
      );
      if (response) {
        dispatch(updateUnPaidPaymentStatus({ id: patientDetails.id }));  
        dispatch(
          subtractClinicAmount({
            type: state.getPayment.modeOfPayment,
            amount: state.getPayment.amount,
          })
        ); 
        handlePaidToUnpaidPayment();
      }
    } catch (error) {
      console.error("error is coming ", error);
    }
  };
  const handlePaidToUnpaidPayment = async () => {
    try {
      const response = await apiServices.patch(
        "authentication",
        `/payment/appointmentUnpaid/${patientDetails.id}`,
        null,
       null,
        null
      );
      if (response) {
        closeSettleDuePayment()
      }
    } catch (error) {
      console.error("error is coming ", error);
    }
  };
  const closeSettleDuePayment = async () => {
    const isChecked = false;
    try {
      const response = await apiServices.patch(
        "authentication",
        "/appointment/setAppointmentVisitStatus",
        {
          Authorization: " Bearer " + token,
        },
        {
          appintmentId: patientDetails.id,
          checked: isChecked,
        },
        null
      );
      if (response) {
        reset();
        toggleState("loadingSpinner", false);
        dispatch(updatePatientNonVisited({ id: patientDetails.id }));
      }
    } catch (error) {
      console.error("Error:", error);
    }finally {
      toggleState("loadingSpinner", false);
    }
  };
  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Select Payment Option
                </p>
              </div>
              {/* form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="overflow-x-auto">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 m-2"
                    >
                      Fees
                    </label>
                    <input
                      type="number"
                      name="fees"
                      value={formik.values.fees}
                      onChange={formik.handleChange}
                      id="card-holder"
                      placeholder="Fees"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.fees && formik.errors.fees ? (
                      <p style={{ color: "red" }}>{formik.errors.fees}</p>
                    ) : null}
                  </div>
                  <div className="grid grid-cols-2 gap-6 mt-4">
                    <div className="col-span-2 sm:col-span-1">
                      <label className="flex items-center space-x-3">
                        <input
                          type="radio"
                          name="modePayment"
                          value="upi"
                          onChange={formik.handleChange}
                          checked={formik.values.modePayment === "upi"}
                          className="form-radio h-5 w-5 text-blue-600"
                        />
                        <span className="text-gray-700">UPI</span>
                      </label>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label className="flex items-center space-x-3">
                        <input
                          type="radio"
                          name="modePayment"
                          value="cash"
                          onChange={formik.handleChange}
                          checked={formik.values.modePayment === "cash"}
                          className="form-radio h-5 w-5 text-blue-600"
                        />
                        <span className="text-gray-700">Cash</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-8 mx-auto">
                  <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-1/2">
                      <div className="relative">
                        <button
                          type="button"
                          onClick={handleUnpaidPayment}
                          className="w-full bg-red-400  rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out"
                        >
                          Unpaid
                        </button>
                      </div>
                    </div>
                    <div className="p-2 w-1/2">
                      <div className="relative">
                        <button
                          type="submit"
                          className="w-full bg-green-500  rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out"
                        >
                          Edit Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default SettleDuePayment;
