import React, { useState } from "react";
import Spinner from "../loading/spinner";
import { toggleState } from "../../specialMethod/toggleState";
import { useHandleEditPatientData } from "./appointment_function/edit_patient_function";

const EditPatient = ({ resetval, patientValue, patientDetails, reset, resett, closeEdit }) => {
  const [state, setState] = useState({
    loadingSpinner: false,
  });

  const { handleEditPatientData } = useHandleEditPatientData(patientDetails, patientValue, reset, resett, resetval, toggleState, setState)

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
    >
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow">
          {/* Close button */}
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            onClick={closeEdit}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close popup</span>
          </button>

          {/* Confirmation text */}
          <div className="p-10">
            Do you really want to change patient details?
            <div className="flex pt-6">
              <button
                type="button"
                onClick={closeEdit}
                className="flex ml-auto text-white bg-gray-500 border-0 py-2 px-6 hover:bg-gray-700 focus:outline-none rounded"
              >
                No
              </button>
              <button
                type="button"
                onClick={handleEditPatientData}
                className="flex ml-2 text-white bg-red-500 border-0 py-2 px-6 hover:bg-red-700 focus:outline-none rounded"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Display spinner when loading */}
      {state.loadingSpinner && <Spinner />}
    </div>
  );
};

export default EditPatient;
