import React, { useState } from "react";
import Spinner from "../loading/spinner";
import { toggleState } from "../../specialMethod/toggleState";
import { useHandleDelete } from "./appointment_function/delete_appoinment";

const DeleteScheduleAppointment = ({ detail, reset }) => {
  const [state, setState] = useState({
    loadingSpinner: false,
  });

  const { handleDelete } = useHandleDelete(detail, toggleState, setState, reset)
  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              <p className="mb-4 text-sm font-normal text-gray-800"></p>

              <div className="">
                <p className="mb-3 text-xl font-semibold leading-5 text-slate-900">
                  Are you sure want to delete this appointment?
                </p>
              </div>
              <div className=" justify-end mt-8 flex space-x-2">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                  onClick={reset}
                >
                  Cancel
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default DeleteScheduleAppointment;
