import { toast } from "react-toastify";
import apiServices from "../../../../Service/apiService";
import { addClinicSucess } from "../../../redux/features/data/dataSlice";
import { useDispatch } from "react-redux";
import { ADD_CLINIC_API, ADD_DENTIST_CHARGES_API, IMAGE_UPLOAD_API } from "../clinic_api/api";
import { addClinicDentistSucess } from "../../../redux/features/dentistData/dentistSlice";

const token = sessionStorage.getItem("token");

export const useAddClinic = (setState, toggleState, image, value, closeAlertClinicBanner, updatedClinics, specialization) => {
    const dispatch = useDispatch();
    const addClinic = async (values) => {
        console.log(values)
        try {
            toggleState("loadingSpinner", true, setState);
            const response = await apiServices.post(
                "authentication",
                ADD_CLINIC_API,
                {
                    Authorization: " Bearer " + token,
                },
                null,
                {
                    clinicName: values.clinicName,
                    location: values.location,
                    startTime: values.startTime,
                    endTime: values.endTime,
                    incharge: values.incharge,
                    clinicContact: values.clinicContact,
                    clinicNewFees: values.clinicNewFees,
                    clinicOldFees:values.clinicOldFees,
                    days: values.days,
                }
            );
            if (response.statusCode === 201) {
                dispatch(addClinicSucess(response.data)); // Dispatch addClinicSuccess with the newly added clinic data
                updatedClinics(response.data);
                if (image) {
                    handleImageUpload(response.data.id, image, toggleState, setState, value, closeAlertClinicBanner);
                }
                if(specialization){
                    handleDentistCharges(response.data.id,values)
                    value();
                    closeAlertClinicBanner();
                }
                
                toggleState("loadingSpinner", false, setState);
                value();
                closeAlertClinicBanner();
            }
        } catch (error) {
            console.error("error", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusCode === 500
            ) {
                toast.error("Something Went Wrong", {
                    position: "top-center",
                });
            }
        }
    };
    const handleDentistCharges= async(clinicId,values)=>{
        try {
            const response = await apiServices.post(
                "authentication",
                ADD_DENTIST_CHARGES_API,
                null,
                null,
                {
                    clinicId:clinicId,
                    generalDentistry: values.generalDentistry,
                    cosmeticDentistry: values.cosmeticDentistry,
                    dentalCheckUp: values.dentalCheckUp,
                    restorationAndFilling: values.restorationAndFilling,
                    crownsAndBridges: values.crownsAndBridges,
                    fractureAndTrauma: values.fractureAndTrauma,
                    vaneersAndLaminates: values.vaneersAndLaminates,
                    extraction: values.extraction,
                    oralAndMaxillofacialSurgery: values.oralAndMaxillofacialSurgery,
                    orthodonticsTreatment: values.orthodonticsTreatment,
                    childDentistry: values.childDentistry,
                    implant: values.implant,
                    scalingAndTeethCleaning: values.scalingAndTeethCleaning,
                    rootCanalTreatment: values.rootCanalTreatment
                }
            );
            if (response.statusCode === 201) {
                dispatch(addClinicDentistSucess(response.data))
                console.log("charges added",response.data)
            }
        } catch (error) {
            console.error("error", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.statusCode === 500
            ) {
                toast.error("Something Went Wrong", {
                    position: "top-center",
                });
            }
        }
    }
    return { addClinic }
}

export const handleImageUpload = async (ClinicId, image, toggleState, setState) => {
    try {
        const base64String = image;
        // Extract image type from base64 string
        const typeMatch = base64String.match(/^data:(image\/\w+);base64,/);
        if (!typeMatch) {
            throw new Error("Invalid base64 string");
        }
        const imageType = typeMatch[1].split("/")[1];

        // Convert base64 to Blob
        const byteCharacters = atob(base64String.split(",")[1]);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: `image/${imageType}` });

        // Create a file from the Blob
        const filename = `image.${imageType}`;
        const file = new File([blob], filename, { type: `image/${imageType}` });
        // Prepare image data for upload
        const imageData = new FormData();
        imageData.append("file", file);

        const response = await apiServices.put(
            "authentication",
            IMAGE_UPLOAD_API,
            {
                Authorization: " Bearer " + token,
            },
            {
                clinicId: ClinicId,
            },
            imageData
        );
        if (response) {
            
            toggleState("loadingSpinner", false, setState);
            return response;
        }
    } catch (error) {
        console.error("Error is coming in api", error);
    }
};



export const useOnCropDone = (image, toggleState, setState) => {
    const onCropDone = (imgCroppedArea) => {
        console.log(imgCroppedArea)
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;
        const context = canvasEle.getContext("2d");
        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
            const dataURL = canvasEle.toDataURL("image/jpeg");
            toggleState("currentPage", false, setState);
            toggleState("image", dataURL, setState);
        };
    };

    return { onCropDone };
};

export const useOnCropCancel = (toggleState, setState) => {
    const onCropCancel = () => {
        toggleState("currentPage", false, setState);
        toggleState("image", "", setState);
    };

    return { onCropCancel }
}

export const getAllDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
];

export const formFields = [
    {
        name: "clinicName",
        type: "text",
        placeholder: "Clinic Name",
        label: "Clinic Name",
    },
    {
        name: "location",
        type: "text",
        placeholder: "Address",
        label: "Address",
    },
    {
        name: "startTime",
        type: "time",
        placeholder: "Start Time",
        label: "Start Time",
    },
    {
        name: "endTime",
        type: "time",
        placeholder: "End Time",
        label: "End Time",
    },
    {
        name: "incharge",
        type: "text",
        placeholder: "Incharge Name",
        label: "Incharge",
    },
    {
        name: "clinicContact",
        type: "number",
        placeholder: "Mobile Number",
        label: "Mobile Number",
    },
    {
        name: "clinicNewFees",
        type: "number",
        placeholder: "₹",
        label: "Clinic New Patient Fee",
    },
    {
        name: "clinicOldFees",
        type: "number",
        placeholder: "₹",
        label: "Clinic Old Patient Fee",
    },
];


export const dentistFields = [
    {
        name: "generalDentistry",
        type: "text",
        placeholder: "General Dentistry",
        label: "General Dentistry",
    },
    {
        name: "fractureAndTrauma",
        type: "text",
        placeholder: "Fracture & Trauma",
        label: "Fracture & Trauma",
    },
    {
        name: "childDentistry",
        type: "text",
        placeholder: "Child Dentistry",
        label: "Child Dentistry",
    },
    {
        name: "cosmeticDentistry",
        type: "text",
        placeholder: "Cosmteic Dentistry",
        label: "Cosmteic Dentistry",
    },
    {
        name: "vaneersAndLaminates",
        type: "text",
        placeholder: "Vaneers & Laminates",
        label: "Vaneers & Laminates",
    },
    {
        name: "implant",
        type: "text",
        placeholder: "Implant",
        label: "Implant",
    },
    {
        name: "dentalCheckUp",
        type: "text",
        placeholder: "Dental Check Up",
        label: "Dental Check Up",
    },
    {
        name: "extraction",
        type: "text",
        placeholder: "Extraction",
        label: "Extraction",
    },
    {
        name: "scalingAndTeethCleaning",
        type: "text",
        placeholder: "Scaling & Teethe Cleaning",
        label: "Scaling & Teethe Cleaning",
    },
    {
        name: "restorationAndFilling",
        type: "text",
        placeholder: "Restoration & Filling",
        label: "Restoration & Filling",
    },
    {
        name: "oralAndMaxillofacialSurgery",
        type: "text",
        placeholder: "Oral & Maxillofacial Surgery",
        label: "Oral & Maxillofacial Surgery",
    },
    {
        name: "rootCanalTreatment",
        type: "text",
        placeholder: "Root Canal Treatment",
        label: "Root Canal Treatment",
    },
    {
        name: "crownsAndBridges",
        type: "text",
        placeholder: "Crowns & Bridges",
        label: "Crowns & Bridges",
    },
    {
        name: "orthodonticsTreatment",
        type: "text",
        placeholder: "Orthodontics Treatment",
        label: "Orthodontics Treatment",
    },
];