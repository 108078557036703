import React, { useEffect, useState } from 'react'
import useData from '../../../redux/customHook/useData';
import apiServices from '../../../../Service/apiService';
import { useDispatch } from 'react-redux';
import { upatePatientVisited, updatePaymentStatus } from '../../../redux/features/allAppointment/appointmentSlice';
import { addClinicAmount } from '../../../redux/features/clinicIncome/clinicIncomeSlice';
import DentalPrintInvoice from './dentalPrintInvoice';
import Spinner from "../../loading/spinner"

const UnpaidtoPaid = ({ patientDetails, reset }) => {
  const { data } = useData();
  const [state, setState] = useState({
    loadingSpinner: false,
    clinicId: null,
    services: null,
    IsPrintInvoice: false,
  });
  const token = sessionStorage.getItem("token");
  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };


  useEffect(() => {
    const handlegetPayment = async () => {
      toggleState("loadingSpinner", true);
      try {
        const response = await apiServices.get(
          "authentication",
          '/TemporaryDentalChargesData/get',
          null,
         {
          appointmentId : patientDetails.id
         },null
        );
        if (response) {
          console.log(response.data);
          const mappedServices = Object.entries(response.data)
          ?.filter(([key, value]) => value != null && key !== "appointmentId")
          ?.map(([key, value]) => ({
            name: key
              .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
              .trim() // Remove extra spaces
              .replace("Surgery", "Surg.") // Remove "Surgery"
              .replace(/\b\w/g, (char) => char.toUpperCase()), // Capitalize first letter of each word
            Charges: value,
          }));
        toggleState("services", mappedServices);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Error:", error);
        } else {
          console.error("Error:", error);
        }
      } finally {
        toggleState("loadingSpinner", false);
      }
    };
    handlegetPayment();
  }, [patientDetails.id]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.clinicDtos) {
      const clinicData = data.clinicDtos.find(
        (clinic) => clinic.location === patientDetails.clinicLocation
      );
      toggleState("clinicId", clinicData.id, setState);
    }
  }, []);

  const total = state.services?.reduce((previousValue, currentValue) => {
    const charge = parseFloat(currentValue.Charges); // Use parseFloat to allow decimal values if necessary
    return previousValue + (isNaN(charge) ? 0 : charge); // Add charge if valid, otherwise add 0
  }, 0);
  
  // Update service charges dynamically
  const handleChargeChange = (index, newCharge) => {
    const updatedServices = [...state.services];
    updatedServices[index].Charges = newCharge === "" ? "" : parseInt(newCharge, 10);
    toggleState("services", updatedServices)
    console.log(updatedServices)
    handleUpdateCharges(updatedServices);
  };

  const handleUpdateCharges = async (updatedServices) => {
    toggleState("loadingSpinner", true);
  
    const requestBody = {
      appointmentId: patientDetails.id, // Include appointmentId
      ...Object.fromEntries(
        updatedServices.map((service) => [
          // Transform service.name to camelCase
          service.name
            .split(" ")
            .map((word, index) =>
              index === 0
                ? word.toLowerCase() // First word is lowercase
                : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize subsequent words
            )
            .join(""), // Join the words back together
          service.Charges || null, // Map Charges, defaulting to null if not provided
        ])
      ),
    };    
  
    try {
      const response = await apiServices.put(
        "authentication",
        "/TemporaryDentalChargesData/update",
        null,
        null,
        requestBody
      );
  
      if (response) {
        console.log(response.data);
        toggleState("services", updatedServices);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Error:", error);
      } else {
        console.error("Error:", error);
      }
    } finally {
      toggleState("loadingSpinner", false);
    }
  };

  const handleCASHPayment = async () => {
    try {
      toggleState("loadingSpinner", true);
      const response = await apiServices.post(
        "authentication",
        "/payment/createAppointmentPayment",
        null,
        null,
        {
          modeOfPayment: "cash",
          amount: total,
          appointmentDate: patientDetails.appointmentDate,
          appointmentId: patientDetails.id,
          clinicId: state.clinicId,
          doctorId: data.id
        }
      );
      if (response) {
        console.log(response)
        handleUnpaidToPaidPayment()
        dispatch(updatePaymentStatus({ id: patientDetails.id }));
        dispatch(
          addClinicAmount({
            type: "cash",
            amount: total,
          })
        );
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const handleUPIPayment = async () => {
    try {
      toggleState("loadingSpinner", true);
      const response = await apiServices.post(
        "authentication",
        "/payment/createAppointmentPayment",
        null,
        null,
        {
          modeOfPayment: "upi",
          amount: total,
          appointmentDate: patientDetails.appointmentDate,
          appointmentId: patientDetails.id,
          clinicId: state.clinicId,
          doctorId: data.id
        }
      );
      if (response) {
        console.log(response)
        handleUnpaidToPaidPayment()
        dispatch(updatePaymentStatus({ id: patientDetails.id }));
        dispatch(
          addClinicAmount({
            type: "upi",
            amount: total,
          })
        );
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleUnpaidToPaidPayment = async () => {
    try {
      const response = await apiServices.patch(
        "authentication",
        `/payment/appointmentPaid/${patientDetails.id}`,
        null,
        null,
        null
      );
      if (response) {
        console.log(response)
        closeSettleDuePayment();
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const closeSettleDuePayment = async () => {
    const isChecked = true;
    try {
      const response = await apiServices.patch(
        "authentication",
        "/appointment/setAppointmentVisitStatus",
        {
          Authorization: " Bearer " + token,
        },
        {
          appintmentId: patientDetails.id,
          checked: isChecked,
        },
        null
      );
      if (response) {
        dispatch(upatePatientVisited({ id: patientDetails.id }));
        toggleState("loadingSpinner", false);
        handlePrintInvoice()
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      toggleState("loadingSpinner", false);
    }
  };

  const handlePrintInvoice = () => {
    toggleState("IsPrintInvoice", true);
  }

  const handleClosePrintInvoice=()=>{
    toggleState("IsPrintInvoice", false);
    reset()
  }
  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
    >
      <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            onClick={reset}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close popup</span>
          </button>
          <div className="p-5">
            <div className="text-center">
              <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                Select Payment Option
              </p>
            </div>
            <div className="grid grid-cols-1 gap-1 p-2 rounded-lg my-2">
              <div className="flex border-gray-200 py-2">
                <span className="text-gray-900 font-semibold mx-2">Services</span>
                <span className="ml-auto text-gray-900 font-semibold mx-2">
                  Amount
                </span>
              </div>
              {state.services?.map((service, index) => (
                <div
                  key={index}
                  className="flex border border-gray-500 rounded-lg py-2"
                >
                  <span className="text-gray-500 ml-2">{service.name}</span>
                  <span className="ml-auto text-gray-900 border-l px-4 border-gray-400 mx-2">
                    <input
                      type="number"
                      value={service.Charges}
                      onChange={(e) =>
                        handleChargeChange(index, e.target.value)
                      }
                      className="w-16 border rounded"
                    />
                  </span>
                </div>
              ))}
              <div className="flex py-2">
                <span className="text-blue-500 font-semibold ml-2">
                  Total Amount
                </span>
                <span className="ml-auto text-blue-500 font-semibold text-xl px-4 border-gray-400 mx-2">
                  Rs {total}/-
                </span>
              </div>
          
            </div>
            <div className="mx-auto">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <button
                      type="button"
                      name="modePayment"
                      onClick={handleUPIPayment}
                      className="w-full bg-orange-400 rounded-3xl border border-gray-300 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out"
                    >
                      Pay using UPI
                    </button>
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <button
                      type="buton"
                      name="modePayment"
                      onClick={handleCASHPayment}
                      className="w-full bg-orange-400 rounded-3xl border border-gray-300 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out"
                    >
                      Pay using Cash
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
      {state.IsPrintInvoice && <DentalPrintInvoice reset={handleClosePrintInvoice} patientDetails={patientDetails} services={state.services} total={total}/>}
    </div>

  );
};

export default UnpaidtoPaid;
