import { fetchDentistApi } from "../../api/fetchDentistApi";
import { fetchDataFailure, fetchDataStart, fetchDataSuccess } from "./dentistSlice";


export const fetchDentistData = (data) => async (dispatch, getState) => {
  const { isDentistDataFetched } = getState().dentistData;
  if (!isDentistDataFetched) {
    //check if data is not already fetched
    dispatch(fetchDataStart());
  }
  try {
    const datas = await fetchDentistApi(data);
    dispatch(fetchDataSuccess(datas));
  } catch (error) {
    dispatch(fetchDataFailure(error.message));
  }
};
