import React, { useEffect, useState } from "react";
import Spinner from "../loading/spinner";
import NewPatientForm from "./newPatientForm";
import { toggleState } from "../../specialMethod/toggleState";

const PatientList = ({ patientDetails, reset, responseData }) => {
    const [state, setState] = useState({
        loading: false,
        selectedPatient: null,
        isPatient: false
    });
    const handleRadioChange = (patient) => {
        toggleState("selectedPatient", patient,setState)
        console.log("Selected Patient ID:", patient);
    };
    const patientListOpen = () => {
        toggleState("isPatient", true,setState)
    }
    const newPatientListOpen = () => {
        toggleState("selectedPatient", "",setState)
        toggleState("isPatient", true,setState)
    }

    return (
        <>
            <div
                id="login-popup"
                tabIndex="-1"
                className={`bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex ${state.loading ? "hidden" : ""
                    }`}
            >
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div className="relative bg-white rounded-lg shadow">
                        <button
                            onClick={reset}
                            type="button"
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="#c6c7c7"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <span className="sr-only">Close popup</span>
                        </button>

                        <div className="p-5">
                            <div className="text-center">
                                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                                    Book Appoinment
                                </p>
                            </div>
                            &nbsp;
                            {/* Loading Spinner */}
                            {state.loading ? (
                                <div className="flex justify-center py-10">
                                    <Spinner />
                                </div>
                            ) : state.error ? (
                                // Error message
                                <div className="text-red-500 text-center py-5">
                                    {state.error}
                                </div>
                            ) : (
                                // Patient table
                                <div className="w-full mx-auto overflow-auto">
                                    <table className="table-auto w-full text-left whitespace-no-wrap">
                                        <thead>
                                            <tr>
                                                <th className="w-10 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br"></th>
                                                <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                                                    Name
                                                </th>
                                                <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                                                    Age
                                                </th>
                                                <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                                                    Gender
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {patientDetails?.data?.length > 0 ? (
                                                patientDetails?.data.map((patient) => (
                                                    <tr className="cursor-pointer" key={patient.id} onClick={() => handleRadioChange(patient)}>
                                                        <td className="w-10 text-center">
                                                            <input name="id" type="radio" onChange={() => handleRadioChange(patient)} // Capture the ID
                                                                checked={state.selectedPatient === patient} />
                                                        </td>
                                                        <td className="px-4 py-3">{patient.name}</td>
                                                        <td className="px-4 py-3">{patient.age}</td>
                                                        <td className="px-4 py-3">{patient.gender}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4" className="text-center py-8">
                                                        No Patient on this mobile number!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {/* Footer Buttons */}
                            <div className="flex pl-4 mt-4 w-full mx-auto">
                                {patientDetails?.data?.length > -1 ? <button class="bg-green-500 text-white inline-flex border-0 py-2 px-6 rounded items-center md:mb-2 lg:mb-0" onClick={patientListOpen}>Continue
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </button> : ""}

                                {patientDetails?.data?.length > 5 ? "" :
                                    <button class="bg-blue-500 text-white flex ml-auto border-0 py-2 px-6 rounded items-center md:mb-2 lg:mb-0" onClick={newPatientListOpen}>
                                        Add New Patient
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Spinner when background task runs */}
            {state.loading && <Spinner />}

            {/* Appointment Form */}
            {state.isPatient && (
                <NewPatientForm
                    patientDetails={state.selectedPatient}
                    responseData={responseData}
                    resett={() => { toggleState("isPatient", false,setState) }}
                    reset={reset}
                />
            )}
        </>
    );
};

export default PatientList;
