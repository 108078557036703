 export const DIAGNOSIS_OPTIONS = [
  "Abdominal aortic aneurysm",
  "Achilles tendinopathy",
  "Acne",
  "Acute cholecystitis",
  "Acute lymphoblastic leukaemia",
  "Acute myeloid leukaemia",
  "Acute pancreatitis",
  "Addisonâ€™s disease",
  "Adenomyosis",
  "Allergic rhinitis",
  "Alcohol-related liver disease",
  "Allergies",
  "Alzheimerâ€™s disease",
  "Anal cancer",
  "Anaphylaxis",
  "Angina",
  "Angioedema",
  "Ankle sprain",
  "Ankylosing spondylitis",
  "Anorexia nervosa",
  "Anxiety",
  "Anxiety disorders in children",
  "Appendicitis",
  "Arterial thrombosis",
  "Arthritis",
  "Asbestosis",
  "Asthma",
  "Ataxia",
  "Atopic eczema",
  "Atrial fibrillation",
  "Attention deficit hyperactivity disorder (ADHD)",
  "Autistic spectrum disorder (ASD)",
  "Bacterial vaginosis",
  "Benign prostate enlargement",
  "Bile duct cancer (cholangiocarcinoma)",
  "Binge eating",
  "Bipolar disorder",
  "Bladder cancer",
  "Blood poisoning (sepsis)",
  "Bone cancer",
  "Bone cancer: Teenagers and young adults",
  "Bowel cancer",
  "Bowel incontinence",
  "Bowel polyps",
  "Brain stem death",
  "Brain tumours",
  "Breast cancer",
  "Bronchiectasis",
  "Bronchitis",
  "Bulimia",
  "Bunion",
  "Carcinoid syndrome and carcinoid tumours",
  "Cardiovascular disease",
  "Carpal tunnel syndrome",
  "Catarrh",
  "Cellulitis",
  "Cerebral palsy",
  "Cervical cancer",
  "Cervical spondylosis",
  "Chest and rib injury",
  "Chest infection",
  "Chickenpox",
  "Chilblains",
  "Chlamydia",
  "Chronic fatigue syndrome",
  "Chronic kidney disease",
  "Chronic lymphocytic leukaemia",
  "Chronic myeloid leukaemia",
  "Chronic obstructive pulmonary disease (COPD)",
  "Chronic pain",
  "Chronic pancreatitis",
  "Cirrhosis",
  "Clostridium difficile",
  "Coeliac disease",
  "Cold sore",
  "Coma",
  "Common cold",
  "Congenital heart disease",
  "Conjunctivitis",
  "Constipation",
  "Coronary heart disease",
  "Coronavirus (COVID-19)",
  "Costochondritis",
  "Cough",
  "Crohnâ€™s disease",
  "Croup",
  "Cystic fibrosis",
  "Cystitis",
  "Deafblindness",
  "Deep vein thrombosis",
  "Degenerative Cervical Myelopathy",
  "Dehydration",
  "Delirium",
  "Dementia",
  "Dental abscess",
  "Depression",
  "Dermatitis herpetiformis",
  "Diabetes",
  "Diabetic retinopathy",
  "Diarrhoea",
  "Discoid eczema",
  "Diverticular disease and diverticulitis",
  "Dizziness (Lightheadedness)",
  "Downâ€™s syndrome",
  "Dry mouth",
  "Dysphagia (swallowing problems)",
  "Dystonia",
  "Earache",
  "Earwax build-up",
  "Ebola virus disease",
  "Ectopic pregnancy",
  "Edwardsâ€™ syndrome",
  "Endometriosis",
  "Epilepsy",
  "Erectile dysfunction (impotence)",
  "Escherichia coli (E. coli) O157",
  "Ewing sarcoma",
  "Ewing sarcoma: Children",
  "Eye cancer",
  "Farting",
  "Febrile seizures",
  "Feeling of something in your throat (Globus)",
  "Fever in adults",
  "Fever in children",
  "Fibroids",
  "Fibromyalgia",
  "Flu",
  "Foetal alcohol syndrome",
  "Food allergy",
  "Food poisoning",
  "Frozen shoulder",
  "Functional neurological disorder (FND)",
  "Fungal nail infection",
  "Gallbladder cancer",
  "Gallstones",
  "Ganglion cyst",
  "Gastroenteritis",
  "Gastro-oesophageal reflux disease (GORD)",
  "Genital herpes",
  "Genital symptoms",
  "Genital warts",
  "Germ cell tumours",
  "Glandular fever",
  "Golfers elbow",
  "Gonorrhoea",
  "Gout",
  "Greater trochanteric pain syndrome",
  "Gum disease",
  "Haemorrhoids (piles)",
  "Hand, foot and mouth disease",
  "Hay fever",
  "Head and neck cancer",
  "Head lice and nits",
  "Headaches",
  "Hearing loss",
  "Heart attack",
  "Heart block",
  "Heart failure",
  "Heart palpitations",
  "Hepatitis A",
  "Hepatitis B",
  "Hepatitis C",
  "Hiatus hernia",
  "High blood pressure (hypertension)",
  "High cholesterol",
  "HIV",
  "Hodgkin lymphoma",
  "Hodgkin lymphoma",
  "Huntingtonâ€™s disease",
  "Hyperglycaemia",
  "Idiopathic pulmonary fibrosis",
  "If your child has cold or flu symptoms",
  "Impetigo",
  "Indigestion",
  "Ingrown toenail",
  "Infertility",
  "Inflammatory bowel disease (IBD)",
  "Inherited heart conditions",
  "Insomnia",
  "Iron deficiency anaemia",
  "Irritable bowel syndrome (IBS)",
  "Itching",
  "Itchy bottom",
  "Itchy skin",
  "Joint hypermobility",
  "Kaposiâ€™s sarcoma",
  "Kidney cancer",
  "Kidney infection",
  "Kidney stones",
  "Labyrinthitis",
  "Lactose intolerance",
  "Laryngeal (larynx) cancer",
  "Laryngitis",
  "Leg cramps",
  "Lichen planus",
  "Lipoedema",
  "Liver cancer",
  "Liver disease",
  "Liver tumours",
  "Long-term effects of COVID-19",
  "Loss of libido",
  "Low blood pressure (hypotension)",
  "Lumbar stenosis",
  "Lung cancer",
  "Lupus",
  "Lyme disease",
  "Lymphoedema",
  "Lymphogranuloma venereum (LGV)",
  "Malaria",
  "Malignant brain tumour (cancerous)",
  "Malnutrition",
  "Managing genital symptoms",
  "Measles",
  "Meningitis",
  "Meniereâ€™s disease",
  "Menopause",
  "Mesothelioma",
  "Middle ear infection (otitis media)",
  "Migraine",
  "Miscarriage",
  "Motor neurone disease (MND)",
  "Mouth cancer",
  "Mouth ulcer",
  "Multiple myeloma",
  "Multiple sclerosis (MS)",
  "Multiple system atrophy (MSA)",
  "Mumps",
  "Munchausenâ€™s syndrome",
  "Myalgic encephalomyelitis (ME) or chronic fatigue syndrome (CFS)",
  "Myasthenia gravis",
  "Nasal and sinus cancer",
  "Nasopharyngeal cancer",
  "Neck problems",
  "Neuroendocrine tumours",
  "Non-alcoholic fatty liver disease (NAFLD)",
  "Non-Hodgkin lymphoma",
  "Norovirus",
  "Nosebleed",
  "Obesity",
  "Obsessive compulsive disorder (OCD)",
  "Obstructive sleep apnoea",
  "Oesophageal cancer",
  "Oral thrush in adults",
  "Osteoarthritis",
  "Osteoarthritis of the hip",
  "Osteoarthritis of the knee",
  "Osteoarthritis of the thumb",
  "Osteoporosis",
  "Osteosarcoma",
  "Outer ear infection (otitis externa)",
  "Ovarian cancer",
  "Ovarian cancer: Teenagers and young adults",
  "Ovarian cyst",
  "Overactive thyroid",
  "Pain in the ball of the foot",
  "Pagetâ€™s disease of the nipple",
  "Pancreatic cancer",
  "Panic disorder",
  "Parkinsonâ€™s disease",
  "Patauâ€™s syndrome",
  "Patellofemoral pain syndrome",
  "Pelvic inflammatory disease",
  "Pelvic organ prolapse",
  "Penile cancer",
  "Peripheral neuropathy",
  "Personality disorder",
  "PIMS",
  "Plantar heel pain",
  "Pleurisy",
  "Pneumonia",
  "Polio",
  "Polycystic ovary syndrome (PCOS)",
  "Polymyalgia rheumatica",
  "Post-polio syndrome",
  "Post-traumatic stress disorder (PTSD)",
  "Postural orthostatic tachycardia syndrome (PoTS)",
  "Pre-eclampsia",
  "Pressure ulcers",
  "Progressive supranuclear palsy (PSP)",
  "Prostate cancer",
  "Psoriasis",
  "Psoriatic arthritis",
  "Psychosis",
  "Pubic lice",
  "Pulmonary hypertension",
  "Rare conditions",
  "Rare tumours",
  "Raynaudâ€™s phenomenon",
  "Reactive arthritis",
  "Restless legs syndrome",
  "Respiratory syncytial virus (RSV)",
  "Retinoblastoma",
  "Rhabdomyosarcoma",
  "Rheumatoid arthritis",
  "Ringworm and other fungal infections",
  "Rosacea",
  "Scabies",
  "Scarlet fever",
  "Schizophrenia",
  "Sciatica",
  "Scoliosis",
  "Seasonal affective disorder (SAD)",
  "Sepsis",
  "Septic shock",
  "Shingles",
  "Shortness of breath",
  "Sickle cell disease",
  "Sinusitis",
  "Sjogrenâ€™s syndrome",
  "Skin cancer (melanoma)",
  "Skin cancer (non-melanoma)",
  "Skin light sensitivity (photosensitivity)",
  "Skin rashes in children",
  "Slapped cheek syndrome",
  "Soft tissue sarcomas",
  "Soft tissue sarcomas: Teenagers and young adults",
  "Sore throat",
  "Spleen problems and spleen removal",
  "Stillbirth",
  "Stomach ache and abdominal pain",
  "Stomach cancer",
  "Stomach ulcer",
  "Streptococcus A (strep A)",
  "Stress, anxiety and low mood",
  "Stroke",
  "Subacromial pain syndrome",
  "Sudden infant death syndrome (SIDS)",
  "Suicide",
  "Sunburn",
  "Supraventricular tachycardia",
  "Swollen glands",
  "Syphilis",
  "Tennis elbow",
  "Testicular cancer",
  "Testicular cancer: Teenagers and young adults",
  "Testicular lumps and swellings",
  "Thirst",
  "Threadworms",
  "Thrush",
  "Thyroid cancer",
  "Thyroid cancer: Teenagers and young adults",
  "Tick bites",
  "Tinnitus",
  "Tonsillitis",
  "Tooth decay",
  "Toothache",
  "Touretteâ€™s syndrome",
  "Transient ischaemic attack (TIA)",
  "Transverse myelitis",
  "Trichomonas infection",
  "Trigeminal neuralgia",
  "Tuberculosis (TB)",
  "Type 1 diabetes",
  "Type 2 diabetes",
  "Ulcerative colitis",
  "Underactive thyroid",
  "Urinary incontinence",
  "Urinary tract infection (UTI)",
  "Urticaria (hives)",
  "Vaginal cancer",
  "Vaginal discharge",
  "Varicose eczema",
  "Varicose veins",
  "Venous leg ulcer",
  "Vertigo",
  "Vitamin B12 or folate deficiency anaemia",
  "Vulval cancer",
  "Vomiting",
  "Warts and verrucas",
  "Whiplash",
  "Whooping cough",
  "Wilmsâ€™ tumour",
  "Wolff-Parkinson-White syndrome",
  "Womb (uterus) cancer",
  "Yellow fever",
  "Zika virus"
]


export const TREATMENT_OPTIONS = [
  "General Dentistry",
  "Fracture & Trauma",
  "Child Dentistry",
  "Cosmetic Dentistry",
  "Vaneers & Laminates",
  "Implant",
  "Dental Check Up",
  "Extraction",
  "Scaling & Teethe Cleaning",
  "Restoration & Filling",
  "Crowns & Bridges",
  "Oral & Maxillofacial Surgery",
  "Root Canal Treatment",
  "Orthodontics Treatment"
]
export const EXAMINATION_OPTIONS = [
  "Zika virus"
]
export const PROCEDURE_OPTIONS = [
  "Zika virus"
]


export   const COMPLAINTS_OPTIONS = [
"Abdominal pain",
"Acid reflux",
"Acne",
"Allergic reaction",
"Ankle pain",
"Anxiety",
"Arm pain",
"Back pain",
"Bad breath",
"Balance problems",
"Bloating",
"Blurry vision",
"Breast pain",
"Breathing difficulty",
"Bruising easily",
"Burning sensation",
"Chest pain",
"Chills",
"Cold hands and feet",
"Cold sweats",
"Confusion",
"Constipation",
"Coughing",
"Cramps",
"Dark urine",
"Dehydration",
"Dementia",
"Depression",
"Diarrhea",
"Dizziness",
"Double vision",
"Dry eyes",
"Dry mouth",
"Ear pain",
"Ear ringing",
"Excessive sweating",
"Eye pain",
"Facial pain",
"Fatigue",
"Fever",
"Flashing lights in vision",
"Frequent urination",
"Hair loss",
"Hallucinations",
"Headache",
"Heart palpitations",
"Heartburn",
"Hearing loss",
"Hemorrhoids",
"High blood pressure",
"Hoarseness",
"Hot flashes",
"Inability to concentrate",
"Indigestion",
"Insomnia",
"Itching",
"Joint pain",
"Jumpy vision",
"Leg cramps",
"Leg pain",
"Lightheadedness",
"Low blood pressure",
"Memory loss",
"Mood swings",
"Mouth sores",
"Muscle aches",
"Muscle weakness",
"Nausea",
"Nervousness",
"Night sweats",
"Numbness",
"Painful urination",
"Palpitations",
"Pelvic pain",
"Rash",
"Red eyes",
"Restlessness",
"Runny nose",
"Seizures",
"Sensitivity to light",
"Shakiness",
"Shortness of breath",
"Shoulder pain",
"Sinus congestion",
"Skin rash",
"Slurred speech",
"Sore throat",
"Sore tongue",
"Spitting up blood",
"Stomach cramps",
"Stomach pain",
"Swallowing difficulties",
"Sweating",
"Swelling",
"Tingling sensation",
"Tiredness",
"Toothache",
"Tremors",
"Trouble breathing",
"Trouble sleeping",
"Unintentional weight loss",
"Urinary incontinence",
"Vaginal discharge",
"Vision problems",
"Vomiting",
"Weakness",
"Weight gain",
"Wheezing",
"Yellowing of the skin",
"Abscess",
"Acute pain",
"Alopecia",
"Angina",
"Anorexia",
"Aphasia",
"Appendicitis",
"Arrhythmia",
"Arthritis",
"Asthma",
"Ataxia",
"Back stiffness",
"Bipolar disorder",
"Bleeding gums",
"Blindness",
"Blood in stool",
"Blood in urine",
"Blurred vision",
"Bone pain",
"Burning eyes",
"Cancer pain",
"Carpal tunnel syndrome",
"Cataracts",
"Change in bowel habits",
"Chills and fever",
"Chronic fatigue",
"Chronic pain",
"Clammy skin",
"Cold intolerance",
"Congestion",
"Cyanosis",
"Cystic acne",
"Decreased appetite",
"Decreased urine output",
"Dental abscess",
"Diabetic symptoms",
"Difficulty hearing",
"Disorientation",
"Disrupted sleep",
"Dry scalp",
"Ear discharge",
"Ear infection",
"Eye strain",
"Facial drooping",
"Facial swelling",
"Falls",
"Fainting",
"Flu-like symptoms",
"Fluid retention",
"Frequent headaches",
"Gallbladder pain",
"General discomfort",
"Glaucoma",
"Gout pain",
"Heart murmur",
"Hemoptysis",
"High fever",
"Hyperventilation",
"Impaired coordination",
"Incontinence",
"Inflamed gums",
"Involuntary movements",
"Irregular heartbeat",
"Jaundice",
"Joint stiffness",
"Kidney pain",
"Liver pain",
"Loose stools",
"Loss of appetite",
"Loss of consciousness",
"Low-grade fever",
"Lump in throat",
"Menstrual pain",
"Menstrual irregularities",
"Migraines",
"Morning stiffness",
"Motion sickness",
"Muscle cramping",
"Neck pain",
"Nosebleed",
"Ocular pain",
"Onset of anxiety",
"Pain during sex",
"Pain in flank",
"Pain in groin",
"Pancreatic pain",
"Paresthesia",
"Persistent cough",
"Phantom pain",
"Post-surgery pain",
"Profuse sweating",
"Prolonged fatigue",
"Psoriasis flares",
"Rectal bleeding",
"Respiratory distress",
"Severe headache",
"Short-term memory loss",
"Skin lesions",
"Sleep disturbances",
"Spinal pain",
"Stiff neck",
"Sudden chest pain",
"Swelling in limbs",
"Swollen lymph nodes",
"Tachycardia",
"Tearing eyes",
"Throat discomfort",
"Thyroid pain",
"Tingling in hands",
"Ulcers",
"Uncontrolled movements",
"Upper abdominal pain",
"Urinary retention",
"Vascular pain",
"Vomiting blood",
"Water retention",
"Watery eyes",
"Weak pulse",
"Weakness in limbs",
"Wheezing cough",
"Wrinkles",
"Zoster pain"
]

export   const TEST_OPTIONS = [
  "Complete Blood Count (CBC)",
  "Blood Glucose Test",
  "Lipid Panel",
  "Liver Function Test (LFT)",
  "Kidney Function Test",
  "X-Ray - Chest",
  "X-Ray - Bones",
  "X-Ray - Abdomen",
  "X-Ray - Skull",
  "X-Ray - Spine",
  "Ultrasound - Abdomen",
  "Ultrasound - Pelvis",
  "Ultrasound - Pregnancy",
  "Ultrasound - Thyroid",
  "Ultrasound - Heart (Echocardiogram)",
  "Magnetic Resonance Imaging (MRI) - Brain",
  "MRI - Spine",
  "MRI - Abdomen",
  "MRI - Joints",
  "MRI - Heart",
  "MRI - Blood Vessels (MR Angiography)",
  "Computed Tomography (CT) Scan - Head",
  "CT Scan - Chest",
  "CT Scan - Abdomen",
  "CT Scan - Pelvis",
  "CT Scan - Spine",
  "CT Angiography (CTA)",
  "Mammography - Screening",
  "Mammography - Diagnostic",
  "Bone Density Scan (DEXA) - Hip",
  "Bone Density Scan (DEXA) - Spine",
  "Colonoscopy",
  "Sigmoidoscopy",
  "Gastroscopy",
  "Endoscopic Ultrasound",
  "Capsule Endoscopy",
  "Bronchoscopy",
  "Cystoscopy",
  "Electrocardiogram (ECG/EKG) - Resting",
  "ECG - Stress",
  "Ambulatory ECG (Holter Monitor)",
  "Echocardiogram - Transthoracic",
  "Echocardiogram - Transesophageal",
  "Echocardiogram - Stress",
  "Cardiac Catheterization",
  "Exercise Stress Test - Treadmill",
  "Nuclear Stress Test",
  "Cardiac MRI",
  "Cardiac CT",
  "Coronary Angiography",
  "Carotid Ultrasound",
  "Doppler Ultrasound - Blood Vessels",
  "Ankle-Brachial Index (ABI)",
  "Pulse Oximetry",
  "Pulmonary Function Test (PFT) - Spirometry",
  "PFT - Lung Volumes",
  "PFT - Diffusion Capacity",
  "Arterial Blood Gas (ABG)",
  "Fractional Exhaled Nitric Oxide (FeNO)",
  "Peak Flow Measurement",
  "Chest X-Ray",
  "CT Pulmonary Angiography (CTPA)",
  "Bronchial Provocation Test",
  "Methacholine Challenge Test",
  "Urinalysis - Routine",
  "Urinalysis - Microscopic",
  "24-Hour Urine Collection - Protein",
  "24-Hour Urine Collection - Creatinine",
  "Urine Culture",
  "Urine Pregnancy Test",
  "Stool Test - Occult Blood",
  "Stool Test - Ova and Parasites",
  "Stool Test - Culture",
  "Stool Test - Clostridium difficile",
  "Needle Biopsy - Breast",
  "Needle Biopsy - Liver",
  "Needle Biopsy - Thyroid",
  "Needle Biopsy - Lung",
  "Skin Biopsy - Punch",
  "Skin Biopsy - Shave",
  "Skin Biopsy - Excisional",
  "Bone Marrow Biopsy",
  "Karyotyping - Chromosomal Analysis",
  "DNA Sequencing - Genetic Testing",
  "Gene Panel Testing - Cancer",
  "Gene Panel Testing - Cardiovascular Disease",
  "Gene Panel Testing - Neurological Disorders",
  "Genetic Carrier Screening",
  "Whole Exome Sequencing",
  "Whole Genome Sequencing",
  "Prenatal Genetic Testing - Amniocentesis",
  "Prenatal Genetic Testing - Chorionic Villus Sampling (CVS)",
  "Thyroid Function Test - TSH",
  "Thyroid Function Test - Free T4",
  "Thyroid Function Test - Total T3",
  "Parathyroid Hormone (PTH) Test",
  "Cortisol Test - Blood",
  "Cortisol Test - Saliva",
  "Cortisol Test - Urine",
  "Adrenocorticotropic Hormone (ACTH) Test",
  "Testosterone Levels - Total",
  "Testosterone Levels - Free",
  "Estrogen Levels - Estradiol",
  "Estrogen Levels - Estrone",
  "Progesterone Levels",
  "Follicle Stimulating Hormone (FSH) Test",
  "Luteinizing Hormone (LH) Test",
  "Prolactin Test",
  "Human Chorionic Gonadotropin (HCG) Test",
  "Vitamin D Level",
  "Calcium Level",
  "Phosphorus Level",
  "Magnesium Level",
  "Electrolyte Panel - Sodium",
  "Electrolyte Panel - Potassium",
  "Electrolyte Panel - Chloride",
  "Electrolyte Panel - Bicarbonate",
  "Lipid Panel - Cholesterol",
  "Lipid Panel - Triglycerides",
  "C-Reactive Protein (CRP) Test",
  "Erythrocyte Sedimentation Rate (ESR)",
  "Antinuclear Antibody (ANA) Test",
  "Rheumatoid Factor (RF) Test",
  "Anti-Cyclic Citrullinated Peptide (Anti-CCP) Test",
  "Blood Urea Nitrogen (BUN) Test",
  "Serum Creatinine Test",
  "Creatinine Clearance Test",
  "Glomerular Filtration Rate (GFR) Test",
  "Liver Function Test (ALT)",
  "Liver Function Test (AST)",
  "Liver Function Test (ALP)",
  "Liver Function Test (Bilirubin)",
  "Liver Function Test (Albumin)",
  "Lactate Dehydrogenase (LDH) Test",
  "Hepatitis Panel",
  "HIV Test - ELISA",
  "HIV Test - Western Blot",
  "HIV Test - PCR",
  "Syphilis Test - RPR",
  "Syphilis Test - FTA-ABS",
  "Chlamydia Test - NAAT",
  "Gonorrhea Test - NAAT",
  "Herpes Simplex Virus (HSV) Test",
  "Human Papillomavirus (HPV) Test",
  "Tuberculosis Skin Test (Mantoux)",
  "Tuberculosis Blood Test (IGRA)",
  "COVID-19 PCR Test",
  "COVID-19 Rapid Antigen Test",
  "Influenza PCR Test",
  "Influenza Rapid Test",
  "Mononucleosis Test",
  "Lyme Disease Test",
  "Malaria Blood Smear",
  "Hepatitis A Test",
  "Hepatitis B Test",
  "Hepatitis C Test",
  "Blood Alcohol Test",
  "Toxicology Screen - Drugs",
  "Toxicology Screen - Poisons",
  "Lead Poisoning Test",
  "Mercury Poisoning Test",
  "Carbon Monoxide Test",
  "Electroencephalogram (EEG)",
  "Nerve Conduction Velocity (NCV) Test",
  "Electromyography (EMG)",
  "Evoked Potentials Test",
  "Sleep Study (Polysomnography)",
  "Multiple Sleep Latency Test (MSLT)",
  "Video EEG Monitoring",
  "Tilt Table Test",
  "PCR Test for Viral Infections",
  "Rapid Antigen Test for Bacterial Infections",
  "Serology Test - Antibodies",
  "Hemoglobin A1c Test",
  "Oral Glucose Tolerance Test",
  "Fasting Blood Glucose Test",
  "Glucose Challenge Test",
  "Autoimmune Disease Panel",
  "Inflammatory Marker Panel",
  "Immunoglobulin Test - IgA",
  "Immunoglobulin Test - IgG",
  "Immunoglobulin Test - IgM",
  "Blood Clotting Test - PT/INR",
  "Blood Clotting Test - aPTT",
  "D-Dimer Test",
  "Fibrinogen Test",
  "Platelet Count",
  "Bone Marrow Aspiration",
  "Chorionic Villus Sampling (CVS)",
  "Amniocentesis",
  "Cervical Biopsy",
  "Endometrial Biopsy",
  "Prostate-Specific Antigen (PSA) Test",
  "Digital Rectal Exam (DRE)",
  "Pap Smear",
  "Breast MRI",
  "Pelvic MRI",
  "Cardiac Calcium Scoring",
  "Hemoglobin Electrophoresis",
  "Serum Iron Test",
  "Total Iron-Binding Capacity (TIBC)",
  "Ferritin Test",
  "Vitamin B12 Level",
  "Folate Level",
  "Red Blood Cell Count",
  "White Blood Cell Count",
  "Platelet Count",
  "Serum Protein Electrophoresis (SPEP)",
  "Urine Protein Electrophoresis (UPEP)",
  "Haptoglobin Test",
  "Serum Amylase Test",
  "Serum Lipase Test",
  "Beta-hCG Quantitative Test",
  "Rapid Strep Test",
  "Bone Density Test",
  "Colposcopy",
  "Hysteroscopy",
  "Laparoscopy",
  "Thermography",
  "Sputum Culture",
  "Synovial Fluid Analysis",
  "Pleural Fluid Analysis",
  "Peritoneal Fluid Analysis"
];

export const ADVICE_OPTIONS = [
  "Maintain a healthy diet",
  "Exercise regularly",
  "Get adequate sleep (7-8 hours)",
  "Drink plenty of water",
  "Reduce stress through relaxation techniques",
  "Avoid smoking and alcohol",
  "Monitor blood pressure regularly",
  "Take medications as prescribed",
  "Follow up in 2 weeks",
  "Get a blood test done",
  "Limit caffeine intake",
  "Maintain a balanced work-life schedule",
  "Wear sunscreen when outdoors",
  "Follow a low-salt diet",
  "Practice deep breathing exercises",
  "Keep a symptom diary",
  "Avoid exposure to allergens",
  "Schedule a follow-up appointment",
  "Get vaccinated (flu, COVID-19, etc.)",
  "Adhere to a diabetic-friendly diet",
  "Use prescribed glasses/contact lenses",
  "Limit screen time",
  "Elevate legs to reduce swelling",
  "Take breaks during long sitting periods",
  "Avoid strenuous activity",
  "Consult a specialist if symptoms persist",
  "Undergo physiotherapy",
  "Rest until recovery",
  "Take iron supplements if needed",
  "Monitor glucose levels regularly",
  "Avoid processed foods",
  "Take probiotics for gut health",
  "Apply cold compresses for swelling",
  "Maintain personal hygiene",
  "Keep wounds clean and dry",
  "Use a humidifier at night",
  "Follow a low-fat diet",
  "Avoid crowded places during flu season",
  "Get an annual health check-up",
  "Take calcium supplements",
  "Use an inhaler if breathing worsens",
  "Practice good posture",
  "Limit sodium intake",
  "Monitor weight weekly",
  "Avoid self-medication",
  "Use a CPAP machine if diagnosed with sleep apnea",
  "Get screened for cholesterol levels",
  "Attend mental health counseling if needed",
  "Avoid fast food and sugary drinks",
  "Wear comfortable shoes",
  "Keep active with light exercise",
  "Seek emergency care if symptoms worsen",
  "Avoid sharing personal items",
  "Practice safe sex",
  "Undergo regular dental check-ups",
  "Avoid greasy foods",
  "Get enough sunlight for Vitamin D",
  "Continue physical activity after recovery",
  "Use over-the-counter medication if necessary",
  "Avoid using electronic devices before bed",
  "Get regular eye check-ups",
  "Wash hands frequently",
  "Avoid heavy lifting",
  "Wear protective gear during activities",
  "Check for food intolerances",
  "Use earplugs in noisy environments",
  "Avoid dairy if lactose intolerant",
  "Keep blood sugar under control",
  "Limit alcohol to special occasions",
  "Try yoga or meditation",
  "Use pain relief medication only as directed",
  "Take regular breaks from physical labor",
  "Apply ointment to rashes",
  "Monitor thyroid function regularly",
  "Avoid late-night snacking",
  "Track menstrual cycle",
  "Increase fiber intake",
  "Seek professional help for anxiety",
  "Keep a log of allergic reactions",
  "Get regular mammograms or Pap smears",
  "Use a walking aid if required",
  "Keep up-to-date with vaccinations",
  "Consult a dietitian for weight management",
  "Attend a rehabilitation program if needed",
  "Limit sugar intake",
  "Avoid prolonged exposure to screens",
  "Stretch before and after exercise",
  "Schedule annual physical exams",
  "Use ice packs for pain relief",
  "Switch to ergonomic furniture",
  "Try a low-carb diet if recommended",
  "Take vitamin supplements as needed",
  "Avoid second-hand smoke",
  "Wear a mask in polluted environments",
  "Disinfect surfaces frequently",
  "Follow prescribed rehabilitation exercises",
  "Use a heating pad for muscle pain",
  "Wear compression stockings if advised",
  "Avoid direct sunlight if photosensitive",
  "Take a multivitamin daily if recommended",
  "Limit gluten if intolerant",
  "Use antibacterial soap",
  "Practice portion control during meals",
];
