import apiServices from "../../../../Service/apiService";

export const useHandleEditPatientData = (patientDetails,patientValue,reset,resett,resetval,toggleState,setState) => {
    const handleEditPatientData = async () => {
        toggleState("loadingSpinner", true, setState); // Show spinner
        try {
          const response = await apiServices.put(
            "authentication",
            `/patient/update/${patientDetails?.id}`,
            null,
            null,
            {
              name: patientValue.name,
              abhaNumber: patientValue.abhaNumber,
              age: patientValue.age,
              contact: patientValue.contact,
              gender: patientDetails?.gender || patientValue.gender,
              guardianName: patientValue.guardianName,
              address: patientValue.address,
            }
          );
    
          if (response.statusCode === 200) {
            toggleState("loadingSpinner", false, setState); // Hide spinner
            resetval(); // Reset form or popup
            reset();
            resett();
            return response.data;
          } else {
            // Handle unexpected responses
            toggleState("loadingSpinner", false, setState);
            console.error("Unexpected response:", response);
          }
        } catch (error) {
          toggleState("loadingSpinner", false, setState); // Hide spinner on error
          console.error("Error updating patient data:", error);
        }
      };
    return { handleEditPatientData };
  };