export const nameKeyMap = {
    "General Dentistry": "generalDentistry",
    "Fracture & Trauma": "fractureAndTrauma",
    "Child Dentistry": "childDentistry",
    "Cosmetic Dentistry": "cosmeticDentistry",
    "Vaneers & Laminates": "vaneersAndLaminates",
    "Implant": "implant",
    "Dental Check Up": "dentalCheckUp",
    "Extraction": "extraction",
    "Scaling & Teethe Cleaning": "scalingAndTeethCleaning",
    "Restoration & Filling": "restorationAndFilling",
    "Crowns & Bridges": "crownsAndBridges",
    "Oral & Maxillofacial Surgery": "oralAndMaxillofacialSurgery",
    "Root Canal Treatment": "rootCanalTreatment",
    "Orthodontics Treatment": "orthodonticsTreatment"
  };